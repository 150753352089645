import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BasicMessageDto, SuperAdminTranslationDto, SuperAdminTranslationListDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const SUPERADMIN_TRANSLATIONS_PATH = "superadmin/translations";

const SuperAdminTranslationsQueryKeys = {
    all: [SUPERADMIN_TRANSLATIONS_PATH],
    forClient: (clientId: number) => [...SuperAdminTranslationsQueryKeys.all, clientId],
};

export interface CreateTranslationInputDto extends Pick<SuperAdminTranslationDto, "key" | "translationDe" | "translationEn"> {
    clientId: number | null;
}

export interface UpdateTranslationInputDto {
    translationId: number;
    translation: Pick<SuperAdminTranslationDto, "translationDe" | "translationEn">;
}

export const useSuperadminTranslations = (clientId?: number | null, enabled?: boolean) => {
    return useQuery({
        queryKey: clientId == undefined ? SuperAdminTranslationsQueryKeys.all : SuperAdminTranslationsQueryKeys.forClient(clientId),
        queryFn: ({ queryKey, signal }) => {
            const id = queryKey[1] !== undefined ? `?clientId=${queryKey[1]}` : "";
            return apiGet<SuperAdminTranslationListDto>(`${queryKey[0]}${id}`, { signal });
        },
        enabled,
    });
};

export const useSuperadminCreateTranslation = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CreateTranslationInputDto) => apiPost<SuperAdminTranslationDto>(SUPERADMIN_TRANSLATIONS_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_TRANSLATIONS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_TRANSLATION_CREATED));
        },
    });
};

export const useSuperadminUpdateTranslation = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: UpdateTranslationInputDto) =>
            apiPatch<SuperAdminTranslationDto>(`${SUPERADMIN_TRANSLATIONS_PATH}/${data.translationId}`, data.translation),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_TRANSLATIONS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_TRANSLATION_SAVED));
        },
    });
};

export const useSuperadminDeleteTranslation = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (translationId: number) => apiDelete<BasicMessageDto>(`${SUPERADMIN_TRANSLATIONS_PATH}/${translationId}`),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_TRANSLATIONS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_TRANSLATION_DELETED));
        },
    });
};
