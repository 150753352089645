import { closestCenter, DndContext } from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CancelIcon from "@mui/icons-material/CancelRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Card, CardActions, CardHeader, List, ListItemText, styled, Typography } from "@mui/material";
import { nonNullable, SuperAdminAttributeDto, type IdeaAttributeRelationOrder } from "api-shared";
import { isEqual, sortBy } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "../../../components/Alert";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import { useSuperAdminAttributeCategories } from "../../../domain/superadmin/attribute-categories";
import {
    useSuperAdminCreateIdeaAttributeRelation,
    useSuperAdminDeleteIdeaAttributeRelation,
    useSuperAdminOrderIdeaAttributeRelations,
} from "../../../domain/superadmin/attributes";
import useDialog from "../../../hooks/useDialog";
import { useLanguage } from "../../../hooks/useLanguage";
import useSortableList from "../../../hooks/useSortableList";
import { translateFromProperty } from "../../../lib/translate";
import { translationKeys } from "../../../translations/main-translations";
import AttributeListEntry from "../attributes/AttributeListEntry";
import FieldRelationCardSkeleton from "../attributes/FieldRelationCardSkeleton";
import FieldRelationDialogIdeas, { type FieldRelationIdeasFormData } from "./IdeaFieldRelationDialog";

// Used for component state, keep as minimal as possible and derive everything else during render from query data

enum OrderableItemType {
    Attribute = "attribute",
    Category = "category",
}

type OrderableItem = { type: OrderableItemType; id: number };

const DividedList = styled(List)(({ theme }) => ({
    "& li:first-of-type": {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

const DragAndDropModifiers = [restrictToVerticalAxis, restrictToFirstScrollableAncestor];

// Drag and drop components expect items to have a consistent id property for referencing, but AttributeRelation and AttributeCategory
// have ids, so map them to a common type with an id of type string
type AttributeDndItem = { id: string; type: OrderableItemType; dataId: number; order: number; title: string; isFilled: boolean };

type RelationType = {
    attributeId: number;
    order: number;
    attributeCategoryId: number;
    isFilled: boolean;
};

interface IdeaFieldRelationCardProps {
    relations: RelationType[];
    attributes: SuperAdminAttributeDto[];
    attributeIdsInUse: number[];
    title: string;
    clientId: number;
}

const IdeaFieldRelationCard = ({ relations, attributes, attributeIdsInUse, title, clientId }: IdeaFieldRelationCardProps) => {
    const createRelationMutation = useSuperAdminCreateIdeaAttributeRelation();
    const deleteRelationMutation = useSuperAdminDeleteIdeaAttributeRelation();

    const [relationToDelete, setRelationToDelete] = useState<number>();
    const addRelationDialog = useDialog();

    const attributeCategories = useSuperAdminAttributeCategories(clientId);

    const { t: translate } = useTranslation();
    const language = useLanguage();

    const changeOrderMutation = useSuperAdminOrderIdeaAttributeRelations();

    const sortedCategories = useMemo(() => sortBy(attributeCategories.data ?? [], (c) => c.order), [attributeCategories.data]);
    const defaultOrder = useMemo<OrderableItem[]>(
        () =>
            sortedCategories.flatMap((category) => {
                const categoryRelations = relations.filter((relation) => relation.attributeCategoryId === category.id);
                const sortedRelations = sortBy(categoryRelations, (r) => r.order);
                return [
                    { type: OrderableItemType.Category, id: category.id },
                    ...sortedRelations.map(({ attributeId }) => ({ type: OrderableItemType.Attribute, id: attributeId })),
                ];
            }) ?? [],
        [relations, sortedCategories],
    );

    // not undefined -> changed order of relations, which is not saved yet
    // fall back to persisted sorting, in case no modifications have been done (yet)
    const [updatedOrder, setUpdatedOrder] = useState<OrderableItem[]>();
    const displayOrder = updatedOrder ?? defaultOrder;

    const isReordering = updatedOrder !== undefined;

    const resetReordering = () => setUpdatedOrder(undefined);

    const isValidOrder =
        attributeCategories.data == null || attributeCategories.data.length <= 0 || displayOrder[0]?.type === OrderableItemType.Category;

    const saveOrder = () => {
        if (!isValidOrder) {
            return;
        }
        const newOrder: IdeaAttributeRelationOrder["order"] = [];
        displayOrder.forEach((item) => {
            if (item.type === OrderableItemType.Category) {
                newOrder.push({ categoryId: item.id, attributes: [] });
            } else {
                newOrder[newOrder.length - 1]?.attributes.push(item.id);
            }
        });

        changeOrderMutation.mutate({ clientId, order: newOrder }, { onSuccess: resetReordering });
    };

    const dndItems = displayOrder
        .map(({ type, id }) => {
            if (type === OrderableItemType.Attribute) {
                const relation = relations.find((r) => r.attributeId === id);
                if (relation != null) {
                    return {
                        id: `relation${id}`,
                        type: OrderableItemType.Attribute,
                        dataId: id,
                        order: relation.order,
                        title: attributes.find((a) => a.id === id)?.title ?? "",
                        isFilled: relation.isFilled,
                    };
                }
            } else {
                const category = attributeCategories.data?.find((a) => a.id === id);
                if (category != null) {
                    return {
                        id: `category${id}`,
                        type: OrderableItemType.Category,
                        dataId: id,
                        order: category.order,
                        title: translateFromProperty(category, "name", language),
                        isFilled: relations.some((r) => r.attributeCategoryId === id),
                    };
                }
            }

            return undefined;
        })
        .filter(nonNullable);

    const updateDndItems = (newItems: AttributeDndItem[]) => {
        const mapped = newItems.map(({ type, dataId }) => ({ type, id: dataId }));
        setUpdatedOrder(mapped);
    };

    const { sensors, onDragEnd, onDragStart } = useSortableList({ items: dndItems, updateItems: updateDndItems });

    const deleteRelation = () => {
        if (relationToDelete == null) {
            return;
        }
        deleteRelationMutation.mutate({ attributeId: relationToDelete, clientId }, { onSuccess: () => setRelationToDelete(undefined) });
    };

    const createRelation = ({ attributeId }: FieldRelationIdeasFormData): void => {
        if (attributeId === null || sortedCategories.length === 0) {
            return;
        }

        createRelationMutation.mutate(
            {
                attributeId,
                clientId,
                attributeCategoryId: sortedCategories[0].id,
            },
            { onSuccess: addRelationDialog.close },
        );
    };

    if (!attributeCategories.isSuccess) {
        return <FieldRelationCardSkeleton title={title} />;
    }

    return (
        <Card>
            <FieldRelationDialogIdeas
                key={JSON.stringify(relationToDelete)}
                open={addRelationDialog.isOpen}
                onClose={addRelationDialog.close}
                attributes={attributes}
                attributeIdsInUse={attributeIdsInUse}
                clientId={clientId}
                onSave={createRelation}
            />
            <DeleteDialog
                item="relation"
                open={relationToDelete != null}
                onClose={() => setRelationToDelete(undefined)}
                onDelete={deleteRelation}
                translate={translate}
            />
            <CardHeader title={title} />
            {attributeCategories.data.length === 0 ? (
                <Typography p={2}>
                    At least one attribute category must exist. Create one under Custom Fields &rsaquo; Categories.
                </Typography>
            ) : (
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    modifiers={DragAndDropModifiers}
                >
                    <SortableContext items={dndItems} strategy={verticalListSortingStrategy}>
                        <DividedList>
                            {dndItems.map((item, index) =>
                                item.type === OrderableItemType.Attribute ? (
                                    <AttributeListEntry
                                        key={item.id}
                                        id={item.id}
                                        isEditingOrder={isReordering}
                                        isUsed={item.isFilled}
                                        onDelete={() => setRelationToDelete(item.dataId)}
                                    >
                                        <ListItemText inset>{item.title}</ListItemText>
                                    </AttributeListEntry>
                                ) : (
                                    <AttributeListEntry
                                        key={item.id}
                                        id={item.id}
                                        isEditingOrder={isReordering}
                                        isUsed={true}
                                        disabled={true}
                                    >
                                        <ListItemText primaryTypographyProps={{ variant: "subtitle1" }}>{item.title}</ListItemText>
                                    </AttributeListEntry>
                                ),
                            )}
                        </DividedList>
                    </SortableContext>
                    {!isValidOrder ? (
                        <Alert sx={{ m: 1 }} severity="warning">
                            {translate(translationKeys.VDLANG_ATTRIBUTE_CATEGORY_ERROR_FIRST_ITEM_NOT_A_CATEGORY)}
                        </Alert>
                    ) : null}
                </DndContext>
            )}
            <CardActions>
                {isReordering ? (
                    <>
                        <Button
                            startIcon={<SaveIcon />}
                            onClick={saveOrder}
                            variant="contained"
                            disabled={isEqual(defaultOrder, updatedOrder) || !isValidOrder}
                        >
                            Save order
                        </Button>
                        <Button startIcon={<CancelIcon />} onClick={resetReordering}>
                            Cancel
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            startIcon={<AddRoundedIcon />}
                            onClick={addRelationDialog.open}
                            disabled={attributeCategories.data.length === 0}
                        >
                            Add Attribute
                        </Button>
                        <Button
                            startIcon={<EditIcon />}
                            onClick={() => setUpdatedOrder(defaultOrder)}
                            disabled={attributeIdsInUse.length === 0}
                        >
                            Edit order
                        </Button>
                    </>
                )}
            </CardActions>
        </Card>
    );
};

export default IdeaFieldRelationCard;
