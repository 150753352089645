import { Button, Divider, Grid, Typography } from "@mui/material";
import { AttributeTable } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import UploadInput from "../../../components/UploadInput";
import ConfirmDialog from "../../../components/dialogues/ConfirmDialog";
import Select from "../../../components/input/select/Select";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useSuperAdminAttributes } from "../../../domain/superadmin/attributes";
import { useSuperadminCustomers } from "../../../domain/superadmin/clients";
import { useSuperAdminUploadTreeNodes } from "../../../domain/superadmin/tree-nodes";
import useDialog from "../../../hooks/useDialog";
import { translationKeys } from "../../../translations/main-translations";
import SuperAdminContainer from "../SuperAdminContainer.tsx";
import ManageTreeNodes from "./ManageTreeNodes";

interface IFileDetails {
    selectedFile: File | null;
    hasValidFile: boolean;
}

const SuperadminTreeNodes = () => {
    const [selectedClientId, setSelectedClientId] = useState<number>();
    const [selectedAttributeId, setSelectedAttributeId] = useState<number>();
    const [fileDetails, setFileDetails] = useState<IFileDetails>({
        selectedFile: null,
        hasValidFile: false,
    });
    const { t: translate } = useTranslation();
    const { isOpen, closeDialog, openDialog } = useDialog();
    const clientsQuery = useSuperadminCustomers();
    const attributesQuery = useSuperAdminAttributes(selectedClientId, selectedClientId !== undefined);
    const uploadTreeNodes = useSuperAdminUploadTreeNodes().mutate;

    const clientOptions =
        clientsQuery.data?.map((client) => {
            return { value: client.id, label: client.name };
        }) ?? [];

    const selectedClientOption = clientOptions.find(({ value }) => value === selectedClientId);

    const treeAttributes =
        attributesQuery.data
            ?.filter((attribute) => {
                return (
                    attribute.tableName === AttributeTable.TreeNodes &&
                    (attribute.clientId === selectedClientId || attribute.clientId === null)
                );
            })
            .map((attribute) => {
                return { value: attribute.id, label: attribute.title };
            }) ?? [];

    function selectedFileChanged(selectedFile: File, hasValidFile: boolean) {
        return setFileDetails({ selectedFile, hasValidFile });
    }

    function uploadJson() {
        const file = fileDetails.selectedFile;
        if (file === null || selectedClientId === undefined || selectedAttributeId === undefined) {
            return;
        }
        uploadTreeNodes(
            { attributeId: selectedAttributeId, clientId: selectedClientId, file },
            {
                onSuccess: () =>
                    setFileDetails({
                        selectedFile: null,
                        hasValidFile: false,
                    }),
            },
        );
    }

    if (!clientsQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const selectedTreeAttributeOption = treeAttributes.filter((tree) => tree.value === selectedAttributeId);
    return (
        <SuperAdminContainer title="Tree Upload">
            <Divider />

            <Grid container spacing={1} p={2} alignItems="flex-end">
                <Grid item xs={4} md={5}>
                    <Select
                        margin="none"
                        label="Client"
                        value={selectedClientOption}
                        options={clientOptions}
                        onChange={(newValue) => {
                            setSelectedClientId(newValue?.value);
                            setSelectedAttributeId(undefined);
                        }}
                        menuPortalTarget={document.body}
                        isSearchable
                        required
                        isMulti={false}
                    />
                </Grid>
                <Grid item xs={4} md={5}>
                    <Select
                        margin="none"
                        label="Tree"
                        isDisabled={selectedClientId === null}
                        value={selectedTreeAttributeOption}
                        options={treeAttributes}
                        onChange={(newValue) => setSelectedAttributeId(newValue?.value)}
                        menuPortalTarget={document.body}
                        isSearchable
                        required
                        isMulti={false}
                    />
                </Grid>
                <Grid item xs={4} md={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        disabled={!fileDetails.hasValidFile || selectedClientId === null}
                        onClick={openDialog}
                    >
                        {translate(translationKeys.VDLANG_UPLOAD)}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {selectedClientId !== null && selectedAttributeId !== null && (
                        <UploadInput
                            translate={translate}
                            updateFile={selectedFileChanged}
                            file={fileDetails.selectedFile}
                            mimeTypes="application/json"
                        />
                    )}
                    <ConfirmDialog
                        open={isOpen}
                        onClose={closeDialog}
                        item={translationKeys.VDLANG_IMPORT}
                        translate={translate}
                        title={translate(translationKeys.VDLANG_TREE_NODE_IMPORT)}
                        onConfirm={uploadJson}
                    >
                        {translate(translationKeys.VDLANG_TREE_NODE_IMPORT_TEXT, {
                            clientName: selectedClientOption?.label ?? "",
                        })}
                    </ConfirmDialog>
                </Grid>
            </Grid>

            <Divider />

            {selectedClientId !== undefined && selectedAttributeId !== undefined ? (
                <ManageTreeNodes clientId={selectedClientId} attributeId={selectedAttributeId} />
            ) : (
                <Typography variant="subtitle2" color="primary" p={2}>
                    Select Client and Tree to see existing Tree Nodes
                </Typography>
            )}
        </SuperAdminContainer>
    );
};

export default SuperadminTreeNodes;
