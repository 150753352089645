import DeleteIcon from "@mui/icons-material/DeleteRounded";
import { TFunction } from "i18next";
import TableIconButton, { ITableIconButtonProps } from "./TableIconButton";

interface ITableDeleteButtonProps extends Omit<ITableIconButtonProps, "translate"> {
    translate?: TFunction;
}

const TableDeleteButton = ({ title, translate, ...other }: ITableDeleteButtonProps) => {
    let resolvedTitle = title;
    if (title == null && translate !== undefined) {
        resolvedTitle = translate("Delete");
    }

    return (
        <TableIconButton title={resolvedTitle ?? ""} {...other}>
            <DeleteIcon />
        </TableIconButton>
    );
};
export default TableDeleteButton;
