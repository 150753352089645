import { AclPermissions, DEFAULT_POTENTIAL_INTERVALS, UserTier } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import Form from "../../../components/Form";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import { CreateClientInputDto, CreateClientUserDto } from "../../../domain/superadmin/clients";
import { defaultCurrency } from "../../../lib/defaults";
import ClientDialog from "./ClientDialog";
import { IFlatClient } from "./ClientListTable";
import InitialUserDialog from "./InitialUserDialog";

interface CreateClientDialogProps {
    open: boolean;
    onClose: () => void;
    createClient: (client: CreateClientInputDto) => void;
    translate: TFunction;
}

const newClient = {
    id: -1,
    name: "",
    duns: -1,
    alias: "",
    isCustomer: 1,
    adminUserSeats: null,
    advancedUserSeats: null,
    basicUserSeats: null,
    lightUserSeats: 0,
    currencyName: defaultCurrency.name,
    currencyIsoCode: defaultCurrency.isoCode,
    fiscalYear: 0,
    fiscalYearRangePast: 2,
    fiscalYearRangeFuture: 2,
    timezone: "UTC",
    features: {},
    ideaPotentialIntervals: DEFAULT_POTENTIAL_INTERVALS,
    clientGroups: [],
    defaultUserTier: UserTier.Advanced,
    externalUserTier: UserTier.Basic,
    defaultMeasurePermission: AclPermissions.Update,
};

const newUser = {
    createInitialUser: false,
    realname: "",
    email: "",
    password: "",
};

const CreateClientDialog = ({ open, onClose, createClient, translate }: CreateClientDialogProps) => {
    const [client, setClient] = useState<IFlatClient>(newClient);
    const [isValidClient, setIsValidClient] = useState(false);

    const [user, setUser] = useState<CreateClientUserDto>(newUser);
    const [isValidUser, setIsValidUser] = useState(true);

    const updateClient = (client: IFlatClient, isValidClient: boolean) => {
        setIsValidClient(isValidClient);
        setClient(client);
    };

    const updateUser = (user: CreateClientUserDto, isValidUser: boolean) => {
        setUser(user);
        setIsValidUser(isValidUser);
    };

    const reset = () => {
        setIsValidClient(false);
        setIsValidUser(true);
        setClient({ ...newClient });
        setUser({ ...newUser });
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    const onSave = () => {
        if (isValidUser && isValidClient) {
            createClient({ newClient: client, initialUser: user });
            handleClose();
        }
    };

    return (
        <ActionItemDialog
            open={open}
            onClose={handleClose}
            action="create"
            item="client"
            primary="create_client"
            onPrimary={onSave}
            primaryDisabled={!(isValidClient && isValidUser)}
            translate={translate}
        >
            <Form onSubmit={onSave}>
                <ClientDialog client={client} updateClient={updateClient} />
                <InitialUserDialog user={user} updateUser={updateUser} />
            </Form>
        </ActionItemDialog>
    );
};

export default CreateClientDialog;
