import { AttributeRelation, AttributeTarget, SuperAdminAttributeDto, SuperAdminMeasureConfigDto } from "api-shared";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import FieldRelationForm from "./FieldRelationForm";

interface IFieldRelationDialogProps {
    target: AttributeTarget;
    measureConfig: SuperAdminMeasureConfigDto;
    onSave: (data: FieldRelationFormData) => void;
    attributeRelation?: AttributeRelation;
    open: boolean;
    onClose: () => void;
    attributes: SuperAdminAttributeDto[];
    attributeIdsInUse: number[];
}

export interface FieldRelationFormData {
    attributeId: number | null;
    mandatory: boolean;
    gateTaskConfig: number | null;
}

const FieldRelationDialog = ({
    onSave,
    attributeRelation,
    open,
    onClose,
    measureConfig,
    attributes,
    attributeIdsInUse,
    target,
}: IFieldRelationDialogProps) => {
    const { t: translate } = useTranslation();
    const { reset, handleSubmit, control, formState, watch } = useForm<FieldRelationFormData>({
        mode: "onChange",
        defaultValues: {
            attributeId: attributeRelation?.attributeId ?? null,
            // EffectCategoryAttributes are always mandatory
            mandatory: attributeRelation?.mandatory ?? target === AttributeTarget.EffectCategory,
            gateTaskConfig: attributeRelation?.gateTaskConfigId ?? null,
        },
    });

    const submit = handleSubmit(onSave);
    const isEditing = attributeRelation !== undefined;
    const isMandatory = watch("mandatory");

    return (
        <ActionItemDialog
            action={isEditing ? "edit" : "create"}
            item="relation"
            primary={isEditing ? "save_relation" : "create_relation"}
            onPrimary={submit}
            primaryDisabled={!formState.isValid || !formState.isDirty}
            translate={translate}
            open={open}
            onClose={() => {
                onClose();
                reset();
            }}
        >
            <FieldRelationForm
                onSubmit={submit}
                control={control}
                measureConfig={measureConfig}
                target={target}
                attributes={attributes}
                attributeIdsInUse={attributeIdsInUse}
                isAttributeDisabled={isEditing}
                isMandatory={isMandatory}
            />
        </ActionItemDialog>
    );
};

export default FieldRelationDialog;
