import { FormGroup, TextField } from "@mui/material";
import { SuperAdminCostLeverDto } from "api-shared";
import React from "react";
import Form from "../../../components/Form";
import Select from "../../../components/input/select/Select";
import { useValueLevers } from "../../../domain/endpoint";
import { useSuperadminCustomers } from "../../../domain/superadmin/clients";
import { CRUDFormProps } from "../CRUDList";
import { methodCodeValidationRegExp } from "./methodCodeValidationRegExp";

const CostLeverCRUDForm = ({ onSave, item, translate, updateItem, isValid, onValidChanged }: CRUDFormProps<SuperAdminCostLeverDto>) => {
    const valueLevers = useValueLevers();
    const clientsQuery = useSuperadminCustomers();

    const textProps = ["code", "nameDe", "nameEn", "hintDe", "hintEn", "keywordsDe", "keywordsEn"] as const;

    const validateText = (text: unknown) => typeof text === "string" && text.trim().length > 0;

    const validateCode = (text: unknown) => typeof text === "string" && methodCodeValidationRegExp.test(text);

    const validateItem = (item: SuperAdminCostLeverDto) =>
        item.valueLeverId > 0 &&
        textProps.every((propName) => (propName === "code" ? validateCode(item[propName]) : validateText(item[propName])));

    const update = (changes: Partial<SuperAdminCostLeverDto>) => {
        const newItem = { ...item, ...changes };
        const newValid = validateItem(newItem);
        updateItem(newItem);
        if (newValid !== isValid) {
            onValidChanged(newValid);
        }
    };

    const clientOptions = (clientsQuery.data ?? []).map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    const updateText = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => update({ [name]: value });

    const valueLeverOptions = valueLevers.map(({ id, code, alias }) => ({
        value: id,
        label: translate(alias, { code }),
    }));

    return (
        <Form onSubmit={onSave}>
            <FormGroup>
                <Select
                    value={valueLeverOptions.find((option) => option.value === item.valueLeverId)}
                    options={valueLeverOptions}
                    onChange={(option) => option != null && update({ valueLeverId: option.value })}
                    menuPortalTarget={document.body}
                    margin="none"
                />
                {textProps.map((name) => (
                    <TextField
                        value={item[name] ?? ""}
                        onChange={updateText}
                        label={name}
                        name={name}
                        required
                        key={name}
                        error={name === "code" ? !validateCode(item[name] ?? "") : !validateText(item[name] ?? "")}
                        margin="normal"
                        helperText={
                            name === "code"
                                ? "Format: [1-10 letters,numbers,spaces]-[1-10 numbers] - Examples: 'ABCD-12345', 'A1 B2-123'"
                                : ""
                        }
                    />
                ))}
                <Select
                    label="Client"
                    value={clientOptions.find((t) => t.value === item.clientId)}
                    options={clientOptions}
                    onChange={(option) => update({ clientId: option != null ? option.value : null })}
                    menuPortalTarget={document.body}
                    isSearchable
                    required
                    isClearable
                />
            </FormGroup>
        </Form>
    );
};

export default CostLeverCRUDForm;
