import { TFunction } from "i18next";
import React, { ReactElement, useMemo } from "react";
import { CellProps, Column } from "react-table";
import BaseTable, { IBaseTableProps } from "../../components/table/BaseTable";
import TableDeleteButton from "../../components/table/TableDeleteButton";
import TableHeaderCell from "../../components/table/TableHeaderCell";
import TableSettingsButton from "../../components/table/TableSettingsButton";
import { Data, DataWithId } from "./CRUDList";

const fixedColumns = ["delete", "edit"];

type CRUDTableProps<T extends Data> = IBaseTableProps<DataWithId<T>> & {
    columns: Column<DataWithId<T>>[];
    itemName: string;
    translate: TFunction;
    onUpdate?: (id: number) => void;
    onDelete?: (id: number) => void;
};

function CRUDTable<T extends Data>({ columns, itemName, translate, onUpdate, onDelete, ...tableProps }: CRUDTableProps<T>) {
    const columnsWithActions = useMemo<Column<DataWithId<T>>[]>(() => {
        const augmentedColumns = [...columns];
        if (onUpdate !== undefined) {
            augmentedColumns.push({
                Header: TableHeaderCell,
                label: "",
                // use functional accessor here because string accessors cannot be resolved statically for generic types
                accessor: ({ id }) => id,
                id: "edit",
                width: 64,
                disableResizing: true,
                disableSortBy: true,
                Cell: ({ value }: CellProps<DataWithId<T>, number>) => (
                    <TableSettingsButton title={translate(`edit_${itemName}`)} onClick={() => onUpdate(value)} />
                ),
            });
        }
        if (onDelete !== undefined) {
            augmentedColumns.push({
                Header: TableHeaderCell,
                label: "",
                // use functional accessor here because string accessors cannot be resolved statically for generic types
                accessor: ({ id }) => id,
                id: "delete",
                width: 64,
                disableResizing: true,
                disableSortBy: true,
                Cell: ({ value }: CellProps<DataWithId<T>, number>) => (
                    <TableDeleteButton title={translate(`delete_${itemName}`)} onClick={() => onDelete(value)} />
                ),
            });
        }

        // make sure that action columns and the column before are not resizable
        return augmentedColumns.map((column, index, arr) => {
            const isActionColumn = fixedColumns.includes(column.id as string);
            return isActionColumn ? ({ ...column, disableResizing: true } as Column<DataWithId<T>>) : column;
        });
    }, [onDelete, onUpdate, columns, itemName, translate]);

    return <BaseTable<DataWithId<T>> columns={columnsWithActions} translate={translate} {...tableProps} />;
}

// memo breaks generics, so cast back to keep generics alive
export default React.memo(CRUDTable) as <T extends Data>(props: CRUDTableProps<T>) => ReactElement;
