import { zodResolver } from "@hookform/resolvers/zod";
import { FormGroup, TextField } from "@mui/material";
import { zSuperAdminAttributeCategoryCreate } from "api-shared";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import Form from "../../../components/Form";

const zAttributeCategory = zSuperAdminAttributeCategoryCreate.omit({ clientId: true });
export type AttributeCategoryForm = z.infer<typeof zAttributeCategory>;

interface AttributeCategoryDialogProps {
    open: boolean;
    onClose: () => void;
    defaultValues?: AttributeCategoryForm;
    onSave: (data: AttributeCategoryForm) => void;
}

const AttributeCategoryDialog = ({ open, onClose, onSave, defaultValues }: AttributeCategoryDialogProps) => {
    const { t: translate } = useTranslation();

    const isEdit = defaultValues != null;

    const {
        handleSubmit,
        register,
        formState: { isValid, isDirty, errors },
        reset,
    } = useForm({
        mode: "onChange",
        resolver: zodResolver(zAttributeCategory),
        defaultValues: defaultValues ?? { nameDe: "", nameEn: "" },
    });

    return (
        <ActionItemDialog
            action={isEdit ? "edit" : "create"}
            item="attributeCategory"
            primary={`${isEdit ? "save" : "create"}_attributeCategory`}
            onPrimary={handleSubmit(onSave)}
            primaryDisabled={!isValid || !isDirty}
            translate={translate}
            open={open}
            onClose={onClose}
            // Reset state only after the dialog was closed
            TransitionProps={{ onExited: () => reset() }}
            keepDialog
        >
            <Form onSubmit={handleSubmit(onSave)}>
                <FormGroup>
                    <TextField
                        {...register("nameDe")}
                        helperText={errors.nameDe?.message}
                        error={errors.nameDe != null}
                        margin="normal"
                        label="Name DE"
                    />
                    <TextField
                        {...register("nameEn")}
                        helperText={errors.nameEn?.message}
                        error={errors.nameEn != null}
                        margin="normal"
                        label="Name EN"
                    />
                </FormGroup>
            </Form>
        </ActionItemDialog>
    );
};

export default AttributeCategoryDialog;
