import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Stack } from "@mui/material";
import { ClientDto } from "api-shared";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import SearchInput from "../../../components/input/SearchInput";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import BaseTable from "../../../components/table/BaseTable";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableSettingsButton from "../../../components/table/TableSettingsButton";
import TableTextCell from "../../../components/table/TableTextCell";
import {
    useSuperadminClients,
    useSuperadminCreateClient,
    useSuperadminCustomers,
    useSuperadminUpdateClient,
} from "../../../domain/superadmin/clients";
import { defaultCurrency } from "../../../lib/defaults";
import CreateClientDialog from "./CreateClientDialog";
import EditClientDialog from "./EditClientDialog";
import MergeClientDialog from "./MergeClientDialog";

const COLUMNS = {
    id: "#",
    name: "Name",
    alias: "Alias",
    isCustomer: "Customer",
    duns: "DUNS",
    adminUserSeats: "Admin Seats",
    advancedUserSeats: "Advanced/Full User Seats",
    basicUserSeats: "Basic/Contributor User Seats",
    lightUserSeats: "Light/Ideator User Seats",
    fiscalYear: "Fiscalyear",
    fiscalYearRangePast: "Fiscalyear Range Past",
    fiscalYearRangeFuture: "Fiscalyear Range Future",
    currencyName: "Currency",
    timezone: "Timezone",
};

enum ModalType {
    Edit,
    Create,
    Merge,
}

const flattenClient = ({ currencies, ...client }: ClientDto): IFlatClient => {
    const { name, isoCode } = currencies.find(({ isDefault }) => isDefault) ?? defaultCurrency;
    return {
        ...client,
        currencyName: name,
        currencyIsoCode: isoCode,
    };
};

export interface IFlatClient
    extends Omit<
        ClientDto,
        | "currencies"
        | "createdAt"
        | "createdBy"
        | "updatedAt"
        | "updatedBy"
        | "internalContactId"
        | "logoHash"
        | "dashboardColors"
        | "whiteSpotColor"
    > {
    currencyName: string;
    currencyIsoCode: string;
}

const WIDTHS: Record<string, number> = {
    id: 60,
    name: 200,
    alias: 60,
};

const getTextColumn = (accessor: string, label: string) => ({
    label,
    accessor,
    Header: TableHeaderCell,
    Cell: TableTextCell,
    ...(WIDTHS[accessor] != null ? { width: WIDTHS[accessor] } : {}),
});

const ClientListTable = () => {
    const { t: translate } = useTranslation();

    const [openModalType, setOpenModalType] = useState<ModalType | null>(null);
    const [selectedClient, setSelectedClient] = useState<IFlatClient | null>(null);
    const [allClientsEnabled, setAllClientsEnabled] = useState(false);
    const [showAllClients, setShowAllClients] = useState(false);
    const [filter, setFilter] = useState("");
    const clients = useSuperadminCustomers();
    const allClients = useSuperadminClients({ enabled: allClientsEnabled });
    const createClient = useSuperadminCreateClient().mutate;
    const updateClient = useSuperadminUpdateClient().mutate;

    const openEditClientForm = useCallback(
        (client: IFlatClient) => {
            setSelectedClient(client);
            setOpenModalType(ModalType.Edit);
        },
        [setOpenModalType, setSelectedClient],
    );

    const columns: any = useMemo(
        () => [
            ...Object.entries(COLUMNS).map(([k, v]) => getTextColumn(k, v)),
            {
                Header: TableHeaderCell,
                label: "",
                accessor: "OPTIONS" as const,
                disableFilters: true,
                disableSortBy: true,
                maxWidth: 48,
                Cell: ({ row }: CellProps<any, any>) => (
                    <TableSettingsButton title={translate("Edit Company")} onClick={() => openEditClientForm(row.original)} />
                ),
            },
        ],
        [translate, openEditClientForm],
    );

    const closeModal = () => setOpenModalType(null);

    const openCreateClientForm = () => {
        setOpenModalType(ModalType.Create);
        setSelectedClient(null);
    };

    const openMergeModal = () => {
        setAllClientsEnabled(true);
        setOpenModalType(ModalType.Merge);
    };

    const handleShowAllClients = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setAllClientsEnabled(true);
        }
        setShowAllClients(event.target.checked);
    };

    const flatClients = showAllClients ? (allClients.data?.map(flattenClient) ?? []) : (clients.data?.map(flattenClient) ?? []);

    const handleGlobalSearchChange = (value: string) => {
        setFilter(value);
    };

    return (
        <>
            <Divider />

            <Stack direction="row" align-items="center" justifyContent="space-between" p={2}>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" onClick={openCreateClientForm}>
                        Create client
                    </Button>
                    <Button variant="outlined" onClick={openMergeModal}>
                        Merge clients
                    </Button>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox size="small" defaultChecked={showAllClients} onChange={handleShowAllClients} />}
                            label="Show non-customer clients"
                        />
                    </FormGroup>
                </Stack>
                <Stack>
                    <SearchInput translate={translate} onChange={handleGlobalSearchChange} searchKey={filter} />
                </Stack>
            </Stack>

            <Divider />

            {!clients.isSuccess || (showAllClients && !allClients.isSuccess) ? (
                <LoadingAnimation />
            ) : (
                <BaseTable data={flatClients} columns={columns} translate={translate} noDataText="No clients" globalFilter={filter} />
            )}

            {openModalType === ModalType.Edit && selectedClient !== null ? (
                <EditClientDialog
                    key={selectedClient.id}
                    open={openModalType === ModalType.Edit}
                    onClose={closeModal}
                    client={selectedClient}
                    updateClient={updateClient}
                    translate={translate}
                />
            ) : null}
            <CreateClientDialog
                open={openModalType === ModalType.Create}
                onClose={closeModal}
                createClient={createClient}
                translate={translate}
            />
            <MergeClientDialog
                open={openModalType === ModalType.Merge}
                onClose={closeModal}
                clients={allClients?.data}
                translate={translate}
            />
        </>
    );
};

export default ClientListTable;
