import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BasicMessageDto, SuperAdminDomainDto, SuperAdminDomainListDto } from "api-shared";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";

const SUPERADMIN_DOMAINS_PATH = "superadmin/domains";

export const useSuperadminDomains = () => {
    return useQuery({
        queryKey: [SUPERADMIN_DOMAINS_PATH],
        queryFn: ({ signal }) => apiGet<SuperAdminDomainListDto>(SUPERADMIN_DOMAINS_PATH, { signal }),
    });
};

export const useSuperadminCreateDomain = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: SuperAdminDomainDto) => apiPost<SuperAdminDomainDto>(SUPERADMIN_DOMAINS_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_DOMAINS_PATH]);
        },
        meta: {
            ignoreErrors: true,
        },
    });
};

export const useSuperadminUpdateDomain = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id, ...restProps }: Pick<SuperAdminDomainDto, "id" | "clientId" | "originatorId" | "name">) =>
            apiPatch<SuperAdminDomainDto>(`${SUPERADMIN_DOMAINS_PATH}/${id}`, restProps),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_DOMAINS_PATH]);
        },
        meta: {
            ignoreErrors: true,
        },
    });
};

export const useSuperadminDeleteDomain = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (domainId: number) => apiDelete<BasicMessageDto>(`${SUPERADMIN_DOMAINS_PATH}/${domainId}`),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_DOMAINS_PATH]);
        },
    });
};
