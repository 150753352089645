import { Grid, styled, Typography } from "@mui/material";
import { AclNamespaces } from "api-shared";
import { Link, Navigate, Route, useLocation } from "react-router-dom";
import Authorization from "../../components/Authorization.tsx";
import RootRoutes from "../../components/RootRoutes";
import SidebarNavItemButton from "../../components/sidebar/SidebarNavItemButton";
import SidebarNavItemGroup from "../../components/sidebar/SidebarNavItemGroup.tsx";
import SidebarPreferences, { sidebarPreferencesClasses } from "../../components/sidebar/SidebarPreferences";
import SidebarTitleItem from "../../components/sidebar/SidebarTitleItem";
import SidebarLayout from "../../components/SidebarLayout";
import { RouteFor } from "../../lib/routes";
import FieldRelationsIdeas from "./attributes-ideas/IdeaFieldRelations";
import FieldRelations from "./attributes/FieldRelations";
import ClientList from "./clients/ClientList";
import CostLeverCRUD from "./cost-levers/CostLeverCRUD";
import CustomFields from "./custom-fields/CustomFields";
import DomainCRUD from "./domains/DomainCRUD";
import SuperadminSuppliers from "./suppliers/SuperadminSuppliers.tsx";
import TranslationList from "./translations/TranslationList";
import SuperadminTreeNodes from "./tree-nodes/SuperadminTreeNodes";

type TabItem = {
    path: string;
    title: string;
    component: React.ElementType;
};

type TabGroup = {
    title: string;
    items: TabItem[];
};

const Root = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const SuperAdminSidebarPreferences = styled(SidebarPreferences)(({ theme }) => ({
    [`& .${sidebarPreferencesClasses.content}`]: {
        padding: theme.spacing(3, 1, 0),
    },
}));

const FallbackMessage = () => <Typography>Nothing for you to see!</Typography>;

const SuperAdmin = () => {
    const location = useLocation();

    const tabGroups: TabGroup[] = [
        {
            title: "General Settings",
            items: [
                {
                    path: RouteFor.superAdmin.clients,
                    title: "Clients",
                    component: ClientList,
                },
                {
                    path: RouteFor.superAdmin.domains,
                    title: "Domains",
                    component: DomainCRUD,
                },
                {
                    path: RouteFor.superAdmin.costLevers,
                    title: "Methods",
                    component: CostLeverCRUD,
                },
            ],
        },
        {
            title: "Client Customization",
            items: [
                {
                    path: RouteFor.superAdmin.customFields,
                    title: "Custom Fields",
                    component: CustomFields,
                },
                {
                    path: RouteFor.superAdmin.translations,
                    title: "Translations",
                    component: TranslationList,
                },
                {
                    path: RouteFor.superAdmin.treeNodes,
                    title: "Tree Upload",
                    component: SuperadminTreeNodes,
                },
                {
                    path: RouteFor.superAdmin.fieldRelations,
                    title: "Process Configuration",
                    component: FieldRelations,
                },
                {
                    path: RouteFor.superAdmin.fieldRelationsIdeas,
                    title: "Opportunity Configuration",
                    component: FieldRelationsIdeas,
                },
                {
                    path: RouteFor.superAdmin.suppliers,
                    title: "Partner Upload",
                    component: SuperadminSuppliers,
                },
            ],
        },
    ];

    return (
        <Authorization namespace={AclNamespaces.SuperAdmin} fallbackComponent={FallbackMessage}>
            <SidebarLayout
                sidebar={
                    <SuperAdminSidebarPreferences header={<SidebarTitleItem>Super Administration</SidebarTitleItem>}>
                        {tabGroups.map(({ title: groupTitle, items }) => [
                            <SidebarNavItemGroup key={groupTitle}>{groupTitle}</SidebarNavItemGroup>,
                            items.map(({ path, title }) => (
                                <SidebarNavItemButton component={Link} key={title} selected={path === location.pathname} to={path}>
                                    {title}
                                </SidebarNavItemButton>
                            )),
                        ])}
                    </SuperAdminSidebarPreferences>
                }
                collapsedHeader="Super Administration"
            >
                <Root>
                    <RootRoutes>
                        <Route path={RouteFor.superAdmin.view} element={<Navigate replace to={RouteFor.superAdmin.clients} />} />
                        {tabGroups
                            .flatMap((tabGroup) => tabGroup.items)
                            .map(({ path, component: Component }) => (
                                <Route key={path} path={path} element={<Component />} />
                            ))}
                    </RootRoutes>
                </Root>
            </SidebarLayout>
        </Authorization>
    );
};

export default SuperAdmin;
