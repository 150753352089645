import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, styled } from "@mui/material";
import DndItem from "../../../components/draggable-list/DndItem";
import Tooltip from "../../../components/Tooltip";

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: theme.spacing(4.5), // same space as drag handle
}));

interface AttributeListEntryProps {
    id: string | number;
    onDelete?: () => void;
    onEdit?: () => void;
    isEditingOrder: boolean;
    isUsed: boolean;
    disabled?: boolean;
}

const AttributeListEntry = ({
    id,
    isUsed,
    onDelete,
    isEditingOrder,
    onEdit,
    children,
    disabled = false,
}: React.PropsWithChildren<AttributeListEntryProps>) => {
    if (isEditingOrder) {
        return (
            <DndItem id={id} divider showHandle={!disabled} disabled={disabled}>
                {children}
            </DndItem>
        );
    }
    return (
        <ListItem divider>
            <CustomListItemIcon />
            {children}
            <ListItemSecondaryAction>
                {onEdit != null ? (
                    <IconButton onClick={onEdit}>
                        <EditIcon />
                    </IconButton>
                ) : null}
                {onDelete != null ? (
                    <Tooltip disableHoverListener={!isUsed} title="Only rows without assigned elements can be removed">
                        <span>
                            <IconButton onClick={onDelete} disabled={isUsed}>
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                ) : null}
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default AttributeListEntry;
