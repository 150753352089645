import { ClientDto, SuperAdminCostLeverDto } from "api-shared";
import React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableTextCell from "../../../components/table/TableTextCell";
import { useSuperadminCustomers } from "../../../domain/superadmin/clients";
import {
    useSuperadminCostLevers,
    useSuperadminCreateCostLever,
    useSuperadminDeleteCostLever,
    useSuperadminUpdateCostLever,
} from "../../../domain/superadmin/cost-levers";
import SuperAdminContainer from "../SuperAdminContainer.tsx";
import CRUDList from "../CRUDList";
import CostLeverCRUDForm from "./CostLeverCRUDForm";

const resolveClientName = (clientId: number | null, clients: ClientDto[] | undefined) => {
    const client = clients?.find(({ id }) => id === clientId);
    return client?.name ?? "";
};

const makeNewCostLever = (init: Partial<SuperAdminCostLeverDto> = {}): SuperAdminCostLeverDto => ({
    ...init,
    id: 0,
    valueLeverId: 0,
    clientId: null,
    code: "",
    nameDe: "",
    nameEn: "",
    hintDe: "",
    hintEn: "",
    keywordsDe: "",
    keywordsEn: "",
});

const CostLeverList = () => {
    const items = useSuperadminCostLevers();
    const clientsQuery = useSuperadminCustomers();
    const createItem = useSuperadminCreateCostLever().mutate;
    const updateItem = useSuperadminUpdateCostLever().mutate;
    const deleteItem = useSuperadminDeleteCostLever().mutate;
    const { t: translate } = useTranslation();

    const columns = React.useMemo<Column<SuperAdminCostLeverDto>[]>(
        () => [
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Id",
                accessor: "id",
                width: 48,
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Code",
                accessor: "code",
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Client",
                id: "client",
                accessor: (r) => resolveClientName(r.clientId, clientsQuery.data),
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Name (english)",
                accessor: "nameEn",
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Name (german)",
                accessor: "nameDe",
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Hint (english)",
                accessor: "hintEn",
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Hint (german)",
                accessor: "hintDe",
            },
        ],
        [clientsQuery.data],
    );
    return (
        <SuperAdminContainer title="Methods">
            <CRUDList
                columns={columns}
                FormComponent={CostLeverCRUDForm}
                itemName="cost_lever"
                translate={translate}
                items={items.data}
                createItem={createItem}
                updateItem={updateItem}
                deleteItem={deleteItem}
                noDataText="No data"
                itemFactory={makeNewCostLever}
            />
        </SuperAdminContainer>
    );
};

export default React.memo(CostLeverList);
