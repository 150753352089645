import { closestCenter, DndContext } from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CancelIcon from "@mui/icons-material/CancelRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Card, CardActions, CardHeader, List, styled, ListItemText } from "@mui/material";
import {
    AttributeRelation,
    AttributeTarget,
    SuperAdminAttributeDto,
    SuperAdminMeasureConfigDto,
    nonNullable,
    CategorizedOrderList,
} from "api-shared";
import { sortBy, isEqual } from "lodash";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import {
    useSuperAdminCreateAttributeRelation,
    useSuperAdminDeleteAttributeRelation,
    useSuperAdminOrderAttributeRelations,
    useSuperAdminUpdateAttributeRelation,
} from "../../../domain/superadmin/attributes";
import useDialog from "../../../hooks/useDialog";
import useSortableList from "../../../hooks/useSortableList";
import FieldRelationDialog, { FieldRelationFormData } from "./FieldRelationDialog";
import { useSuperAdminAttributeCategories } from "../../../domain/superadmin/attribute-categories";
import FieldRelationCardSkeleton from "./FieldRelationCardSkeleton";
import AttributeListEntry from "./AttributeListEntry";
import { translateFromProperty } from "../../../lib/translate.ts";
import { useLanguage } from "../../../hooks/useLanguage.ts";

enum OrderableItemType {
    Attribute = "attribute",
    Category = "category",
}

type OrderableItem = { type: OrderableItemType; id: number; measureConfigId?: number };

const DividedList = styled(List)(({ theme }) => ({
    "& li:first-of-type": {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

const DragAndDropModifiers = [restrictToVerticalAxis, restrictToFirstScrollableAncestor];

// Drag and drop components expect items to have a consistent id property for referencing, but AttributeRelation and AttributeCategory
// have ids, so map them to a common type with an id of type string
type AttributeDndItem = {
    id: string;
    type: OrderableItemType;
    dataId: number;
    measureConfigId?: number;
    order: number;
    title: string;
    isFilled: boolean;
};

interface FieldRelationCardProps {
    relations: AttributeRelation[];
    attributes: SuperAdminAttributeDto[];
    attributeIdsInUse: number[];
    title: string;
    measureConfig: SuperAdminMeasureConfigDto;
    target: AttributeTarget;
    isFetching: boolean;
    clientId: number;
}

const FieldRelationCard = ({
    relations,
    attributes,
    attributeIdsInUse,
    title,
    measureConfig,
    target,
    isFetching,
    clientId,
}: FieldRelationCardProps) => {
    const createRelationMutation = useSuperAdminCreateAttributeRelation();
    const updateRelationMutation = useSuperAdminUpdateAttributeRelation();
    const deleteRelationMutation = useSuperAdminDeleteAttributeRelation();

    const [relationToDelete, setRelationToDelete] = useState<AttributeRelation>();
    const [relationToEdit, setRelationToEdit] = useState<AttributeRelation>();
    const addRelationDialog = useDialog();
    const deleteRelationDialog = useDialog();

    const attributeCategories = useSuperAdminAttributeCategories(clientId);

    const { t: translate } = useTranslation();
    const language = useLanguage();

    const changeOrderMutation = useSuperAdminOrderAttributeRelations();

    const sortedCategories = useMemo(() => {
        return sortBy(
            [{ id: 0, order: 0, nameDe: "Nicht kategorisiert", nameEn: "Uncategorized" }, ...(attributeCategories.data ?? [])],
            (c) => c.order,
        );
    }, [attributeCategories.data]);
    const defaultOrder = useMemo<OrderableItem[]>(() => {
        if (target === AttributeTarget.EffectCategory) {
            const sortedRelations = sortBy(relations, (r) => r.order);
            return sortedRelations.map(({ attributeId, measureConfigId }) => ({
                type: OrderableItemType.Attribute,
                id: attributeId,
                measureConfigId,
            }));
        } else {
            return (
                sortedCategories.flatMap((category) => {
                    const categoryRelations = relations.filter((relation) =>
                        category.id === 0 ? relation.attributeCategoryId === null : relation.attributeCategoryId === category.id,
                    );
                    const sortedRelations = sortBy(categoryRelations, (r) => r.order);
                    return [
                        { type: OrderableItemType.Category, id: category.id },
                        ...sortedRelations.map(({ attributeId, measureConfigId }) => ({
                            type: OrderableItemType.Attribute,
                            id: attributeId,
                            measureConfigId,
                        })),
                    ];
                }) ?? []
            );
        }
    }, [target, relations, sortedCategories]);

    // not undefined -> changed order of relations, which is not saved yet
    // fall back to persisted sorting, in case no modifications have been done (yet)
    const [updatedOrder, setUpdatedOrder] = useState<OrderableItem[]>();
    const displayOrder = updatedOrder ?? defaultOrder;

    const isReordering = updatedOrder !== undefined;

    const resetReordering = () => setUpdatedOrder(undefined);

    const saveOrder = () => {
        if (target === AttributeTarget.EffectCategory && updatedOrder !== undefined) {
            const newOrderedValues = updatedOrder.filter((rel) => rel.type === OrderableItemType.Attribute).map((rel) => rel.id);
            changeOrderMutation.mutate(
                { measureConfigId: measureConfig.id, order: newOrderedValues, target },
                {
                    onSuccess: resetReordering,
                },
            );
        } else if (target == AttributeTarget.Measure && updatedOrder !== undefined) {
            const newOrder: CategorizedOrderList = [];
            displayOrder.forEach((item) => {
                if (item.type === OrderableItemType.Category) {
                    newOrder.push({ categoryId: item.id === 0 ? null : item.id, attributeIds: [] });
                } else {
                    newOrder[newOrder.length - 1]?.attributeIds.push(item.id);
                }
            });
            changeOrderMutation.mutate({ order: newOrder, target, measureConfigId: measureConfig.id }, { onSuccess: resetReordering });
        }
    };

    const dndItems = displayOrder
        .map(({ type, id, measureConfigId }) => {
            if (type === OrderableItemType.Attribute) {
                const relation = relations.find((r) => r.attributeId === id);
                if (relation != null) {
                    return {
                        id: `relation${id}`,
                        type: OrderableItemType.Attribute,
                        dataId: id,
                        measureConfigId,
                        order: relation.order,
                        title: attributes.find((a) => a.id === id)?.title ?? "",
                        isFilled: relation.isFilled,
                    };
                }
            } else {
                const category = sortedCategories.find((c) => c.id === id);
                if (category != null) {
                    return {
                        id: `category${id}`,
                        type: OrderableItemType.Category,
                        dataId: id,
                        measureConfigId,
                        order: category.order,
                        title: translateFromProperty(category, "name", language),
                        isFilled: relations.some((r) => r.attributeCategoryId === id),
                    };
                }
            }

            return undefined;
        })
        .filter(nonNullable);

    const updateDndItems = (newItems: AttributeDndItem[]) => {
        const mapped = newItems.map(({ type, dataId, measureConfigId }) => ({ type, id: dataId, measureConfigId }));
        setUpdatedOrder(mapped);
    };

    const { sensors, onDragEnd, onDragStart } = useSortableList({ items: dndItems, updateItems: updateDndItems });

    const deleteRelation = () => {
        if (relationToDelete == null) {
            return;
        }
        deleteRelationMutation.mutate(
            {
                target: relationToDelete.target,
                attributeId: relationToDelete.attributeId,
                measureConfigId: relationToDelete.measureConfigId,
            },
            { onSuccess: () => setRelationToDelete(undefined) },
        );
    };

    const createRelation = ({ attributeId, mandatory, gateTaskConfig }: FieldRelationFormData) => {
        if (attributeId === null) {
            return;
        }
        if (relationToEdit === undefined) {
            createRelationMutation.mutate(
                {
                    attributeId,
                    target,
                    measureConfigId: measureConfig.id,
                    mandatory,
                    gateTaskConfigId: gateTaskConfig,
                },
                { onSuccess: () => setRelationToEdit(undefined) },
            );
        } else {
            updateRelationMutation.mutate(
                {
                    attributeId,
                    target,
                    measureConfigId: measureConfig.id,
                    mandatory,
                    gateTaskConfigId: gateTaskConfig,
                },
                {
                    onSuccess: () => {
                        setRelationToEdit(undefined);
                        addRelationDialog.close();
                    },
                },
            );
        }
    };

    if (!attributeCategories.isSuccess) {
        return <FieldRelationCardSkeleton title={title} />;
    }

    return (
        <Card>
            <FieldRelationDialog
                key={JSON.stringify(relationToEdit)}
                open={addRelationDialog.isOpen}
                onClose={() => {
                    setRelationToEdit(undefined);
                    addRelationDialog.close();
                }}
                target={target}
                measureConfig={measureConfig}
                attributes={attributes}
                attributeRelation={relationToEdit}
                attributeIdsInUse={attributeIdsInUse}
                onSave={createRelation}
            />
            <DeleteDialog
                item="relation"
                open={relationToDelete != null}
                onClose={deleteRelationDialog.close}
                onDelete={deleteRelation}
                translate={translate}
            />
            <CardHeader title={title} />
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                modifiers={DragAndDropModifiers}
            >
                <SortableContext items={dndItems} strategy={verticalListSortingStrategy}>
                    <DividedList>
                        {dndItems.map((item, index) =>
                            item.type === OrderableItemType.Attribute ? (
                                <AttributeListEntry
                                    key={item.id}
                                    id={item.id}
                                    isEditingOrder={isReordering}
                                    isUsed={item.isFilled}
                                    onEdit={() => {
                                        const relation = relations.find((r) => r.attributeId === item.dataId);
                                        if (relation == null) {
                                            return;
                                        }
                                        setRelationToEdit(relation);
                                        addRelationDialog.open();
                                    }}
                                    onDelete={() => {
                                        const relation = relations.find((r) => r.attributeId === item.dataId);
                                        if (relation == null) {
                                            return;
                                        }
                                        setRelationToDelete(relation);
                                    }}
                                >
                                    <ListItemText inset>{item.title}</ListItemText>
                                </AttributeListEntry>
                            ) : (
                                <AttributeListEntry key={item.id} id={item.id} isEditingOrder={isReordering} isUsed={true} disabled={true}>
                                    <ListItemText primaryTypographyProps={{ variant: "subtitle1" }}>{item.title}</ListItemText>
                                </AttributeListEntry>
                            ),
                        )}
                    </DividedList>
                </SortableContext>
            </DndContext>
            <CardActions>
                {isReordering ? (
                    <>
                        <Button
                            startIcon={<SaveIcon />}
                            onClick={saveOrder}
                            variant="contained"
                            disabled={isEqual(defaultOrder, updatedOrder) || changeOrderMutation.isLoading}
                        >
                            Save order
                        </Button>
                        <Button startIcon={<CancelIcon />} onClick={resetReordering}>
                            Cancel
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            startIcon={<AddRoundedIcon />}
                            onClick={addRelationDialog.open}
                            disabled={createRelationMutation.isLoading || isFetching}
                        >
                            Add Attribute
                        </Button>
                        <Button
                            startIcon={<EditIcon />}
                            onClick={() => setUpdatedOrder(defaultOrder)}
                            disabled={attributeIdsInUse.length === 0}
                        >
                            Edit order
                        </Button>
                    </>
                )}
            </CardActions>
        </Card>
    );
};

export default FieldRelationCard;
