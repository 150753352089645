import SuperAdminContainer from "../SuperAdminContainer.tsx";
import TranslationListTable from "./TranslationListTable";

const TranslationList = () => (
    <SuperAdminContainer title="Translations">
        <TranslationListTable />
    </SuperAdminContainer>
);

export default TranslationList;
