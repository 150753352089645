import { TextField, Typography } from "@mui/material";
import { ClientDto } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import Select from "../../../components/input/select/Select";
import LoadingAnimation from "../../../components/loading/LoadingAnimation.tsx";
import { useSuperadminMergeClients } from "../../../domain/superadmin/clients";
import { translationKeys } from "../../../translations/main-translations";

const mapClientToOption = ({ id, name }: ClientDto) => ({ label: name, value: id });

export interface MergeClientDialogProps {
    open: boolean;
    onClose: () => void;
    clients?: ClientDto[];
    translate: TFunction;
}

function MergeClientDialog({ open, onClose, clients = [], translate }: Readonly<MergeClientDialogProps>) {
    const [primaryClientId, setPrimaryClientId] = useState<number | null>(null);
    const [secondaryClientId, setSecondaryClientId] = useState<number | null>(null);
    const [verifySecondaryClient, setVerifySecondaryClient] = useState<string>("");
    const mergeClients = useSuperadminMergeClients().mutate;

    // Primary client can be every client, secondary client can only be non-customer
    const primaryClientOptions = clients.map(mapClientToOption);
    const secondaryClientOptions = clients.filter((client) => !client.isCustomer && client.id !== primaryClientId).map(mapClientToOption);

    // Merging is allowed when two clients are selected and the name of the second client is typed into the input
    const secondaryClient = clients.find((client) => client.id === secondaryClientId);
    const isMergingAllowed = primaryClientId && secondaryClientId && secondaryClient && secondaryClient.name === verifySecondaryClient;

    function onSubmit() {
        if (isMergingAllowed && primaryClientId && secondaryClientId) {
            mergeClients({ primary: primaryClientId, secondary: secondaryClientId });
            setPrimaryClientId(null);
            setSecondaryClientId(null);
            setVerifySecondaryClient("");
        }
    }

    return (
        <ActionItemDialog
            open={open}
            onClose={onClose}
            title={translate(translationKeys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_TITLE)}
            primary={translationKeys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_MERGE_BUTTON}
            onPrimary={onSubmit}
            primaryDisabled={!isMergingAllowed}
            primaryDanger
            translate={translate}
        >
            {clients.length === 0 ? (
                <LoadingAnimation />
            ) : (
                <>
                    <Select
                        label={translate(translationKeys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_PRIMARY_CLIENT)}
                        options={primaryClientOptions}
                        value={primaryClientOptions.find((option) => option.value === primaryClientId)}
                        onChange={(option) => setPrimaryClientId(option != null ? option.value : null)}
                        isSearchable
                        menuPortalTarget={document.body}
                    />
                    <Select
                        label={translate(translationKeys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_SECONDARY_CLIENT)}
                        options={secondaryClientOptions}
                        value={secondaryClientOptions.find((option) => option.value === secondaryClientId)}
                        onChange={(option) => setSecondaryClientId(option != null ? option.value : null)}
                        isSearchable
                        menuPortalTarget={document.body}
                    />
                    <Typography>{translate(translationKeys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_INFO)}</Typography>
                    <Typography component="div">
                        <ul>
                            {[1, 2, 3, 4].map((index) => (
                                <li key={index}>{translate(`${translationKeys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_INFO_ITEMS}.${index}`)}</li>
                            ))}
                        </ul>
                    </Typography>
                    <TextField
                        fullWidth
                        value={verifySecondaryClient}
                        onChange={(event) => setVerifySecondaryClient(event.target.value)}
                        placeholder={translate(translationKeys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_VERIFY_CLIENT_NAME)}
                    />
                </>
            )}
        </ActionItemDialog>
    );
}

export default MergeClientDialog;
