import SuperAdminContainer from "../SuperAdminContainer.tsx";
import ClientListTable from "./ClientListTable";

const ClientList = () => (
    <SuperAdminContainer title="Clients">
        <ClientListTable />
    </SuperAdminContainer>
);

export default ClientList;
