import { ClientDto, SuperAdminDomainDto } from "api-shared";
import { pick } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableTextCell from "../../../components/table/TableTextCell";
import { useSuperadminClients } from "../../../domain/superadmin/clients";
import {
    useSuperadminCreateDomain,
    useSuperadminDeleteDomain,
    useSuperadminDomains,
    useSuperadminUpdateDomain,
} from "../../../domain/superadmin/domains";
import SuperAdminContainer from "../SuperAdminContainer.tsx";
import CRUDList from "../CRUDList";
import DomainCRUDForm from "./DomainCRUDForm";

const resolveClientName = (clientId: number | null, clients: ClientDto[] | undefined) => {
    const client = clients?.find(({ id }) => id === clientId);
    return client?.name ?? String(clientId ?? "");
};

const makeNewDomain = (init: Partial<SuperAdminDomainDto> = {}): SuperAdminDomainDto => ({
    ...init,
    id: 0,
    clientId: 0,
    originatorId: null,
    name: "",
});

const DomainCRUD = () => {
    const items = useSuperadminDomains();
    const clientsQuery = useSuperadminClients();

    const createItem = useSuperadminCreateDomain().mutate;
    const updateItem = useSuperadminUpdateDomain().mutate;
    const deleteItem = useSuperadminDeleteDomain().mutate;
    const { t: translate } = useTranslation();

    const columns = React.useMemo<Column<SuperAdminDomainDto>[]>(
        () => [
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Id",
                accessor: "id",
                width: 48,
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Client",
                id: "client",
                accessor: (r) => resolveClientName(r.clientId, clientsQuery.data),
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Originator",
                id: "originator",
                accessor: (r) => resolveClientName(r.originatorId, clientsQuery.data),
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Domain",
                accessor: "name",
            },
        ],
        [clientsQuery.data],
    );
    // Domain update endpoint is not accepting Partial, but has all attributes mandatory, make sure
    // to provide everything
    const handleUpdate = ({ id, ...changes }: { id: number } & Partial<SuperAdminDomainDto>) => {
        const originalItem = (items.data ?? []).find((i) => i.id === id);
        if (originalItem === undefined) {
            return;
        }
        updateItem({ id, ...pick(originalItem, ["clientId", "originatorId", "name"]), ...changes });
    };

    if (!clientsQuery.isSuccess || !items.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <SuperAdminContainer title="Domains">
            <CRUDList
                columns={columns}
                FormComponent={DomainCRUDForm}
                itemName="domain"
                translate={translate}
                items={items.data}
                createItem={createItem}
                updateItem={handleUpdate}
                deleteItem={deleteItem}
                noDataText="No domains"
                itemFactory={makeNewDomain}
            />
        </SuperAdminContainer>
    );
};

export default DomainCRUD;
