import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    AttributeCategoryDto,
    type AttributeCategoryListDto,
    type NumericIdParam,
    type SuperAdminAttributeCategoryCreateDto,
    type SuperAdminAttributeCategoryUpdateDto,
    ErrorConstantKeys,
} from "api-shared";
import queryString from "query-string";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";

const SUPERADMIN_ATTRIBUTE_CATEGORIES_PATH = "superadmin/attribute-categories";

export const SuperAdminAttributeCategoriesQueryKeys = {
    forClient: (clientId?: number) => [SUPERADMIN_ATTRIBUTE_CATEGORIES_PATH, { clientId }] as const,
};

export const useSuperAdminAttributeCategories = (clientId?: number) => {
    return useQuery({
        queryKey: SuperAdminAttributeCategoriesQueryKeys.forClient(clientId),
        queryFn: ({ queryKey: [, { clientId }], signal }) => {
            return apiGet<AttributeCategoryListDto>(`${SUPERADMIN_ATTRIBUTE_CATEGORIES_PATH}?${queryString.stringify({ clientId })}`, {
                signal,
            });
        },
        enabled: clientId != null,
    });
};

export const useSuperAdminCreateAttributeCategory = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: SuperAdminAttributeCategoryCreateDto) =>
            apiPost<AttributeCategoryDto>(SUPERADMIN_ATTRIBUTE_CATEGORIES_PATH, data),
        onSuccess: (_, input) => {
            queryClient.invalidateQueries(SuperAdminAttributeCategoriesQueryKeys.forClient(input.clientId));
        },
    });
};

export const useSuperAdminUpdateAttributeCategory = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id, clientId, ...data }: SuperAdminAttributeCategoryUpdateDto & NumericIdParam & { clientId: number }) =>
            apiPatch<AttributeCategoryDto>(`${SUPERADMIN_ATTRIBUTE_CATEGORIES_PATH}/${id}`, data),
        onSuccess: (_, { clientId }) => {
            queryClient.invalidateQueries(SuperAdminAttributeCategoriesQueryKeys.forClient(clientId));
        },
    });
};

export const useSuperAdminDeleteAttributeCategory = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }: NumericIdParam & { clientId: number }) => apiDelete(`${SUPERADMIN_ATTRIBUTE_CATEGORIES_PATH}/${id}`),
        onSuccess: (_, { clientId }) => {
            queryClient.invalidateQueries(SuperAdminAttributeCategoriesQueryKeys.forClient(clientId));
        },
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_ATTRIBUTE_CATEGORY_IN_USE,
        },
    });
};

export const useSuperAdminChangeAttributeCategoriesOrder = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ clientId, order }: { order: number[] } & { clientId: number }) =>
            apiPost(`${SUPERADMIN_ATTRIBUTE_CATEGORIES_PATH}/change-order`, { clientId, order }),
        onSuccess: (_, { clientId }) => {
            queryClient.invalidateQueries(SuperAdminAttributeCategoriesQueryKeys.forClient(clientId));
        },
    });
};
