/**
 * A regular expression used to validate method codes.
 *
 * This RegExp pattern ensures that:
 * - The code starts with an uppercase letter.
 * - The main body of the code can include uppercase letters, digits, and optional single spaces.
 * - The main body can have a maximum of 10 segments, with each segment optionally separated by a single space.
 * - A hyphen ("-") separates the main body from the numeric identifier.
 * - The numeric identifier at the end can have between 1 and 10 digits.
 */
export const methodCodeValidationRegExp = new RegExp(/^[A-Z](?:[A-Z\d](?: [A-Z\d])?){0,9}-\d{1,10}$/);
