import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    AttributeRelation,
    AttributeRelationCreateDto,
    AttributeRelationOrder,
    AttributeRelationUpdateDto,
    BasicMessageDto,
    IdeaAttributeRelation,
    IdeaAttributeRelationCreateDto,
    IdeaAttributeRelationDeleteDto,
    IdeaAttributeRelationOrder,
    SuperAdminAttributeListDto,
    AttributeRelationDeleteDto,
} from "api-shared";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { SuperAdminAttributeCategoriesQueryKeys } from "./attribute-categories";

const SUPERADMIN_ATTRIBUTES_PATH = "superadmin/attributes";
const SUPERADMIN_ATTRIBUTES_RELATIONS_PATH = `${SUPERADMIN_ATTRIBUTES_PATH}/relations`;
const SUPERADMIN_ATTRIBUTES_RELATIONS_IDEAS_PATH = `${SUPERADMIN_ATTRIBUTES_PATH}/relations/ideas`;

export const SuperAdminAttributesQueryKeys = {
    all: [SUPERADMIN_ATTRIBUTES_PATH, "all"] as const,
    forClient: (clientId?: number) => [SUPERADMIN_ATTRIBUTES_PATH, "client", clientId] as const,
    allRelations: [SUPERADMIN_ATTRIBUTES_PATH, "relations"] as const,
    allIdeaRelations: [SUPERADMIN_ATTRIBUTES_PATH, "relations", "ideas"] as const,
    ideaRelationForId: (id: number) => [...SuperAdminAttributesQueryKeys.allIdeaRelations, id] as const,
};

export const useSuperAdminAttributes = (clientId?: number, enabled?: boolean) => {
    return useQuery({
        queryKey: SuperAdminAttributesQueryKeys.forClient(clientId),
        queryFn: ({ queryKey, signal }) => {
            const id = clientId === undefined ? "" : `?clientId=${clientId}`;
            return apiGet<SuperAdminAttributeListDto>(`${SUPERADMIN_ATTRIBUTES_PATH}${id}`, { signal });
        },
        enabled,
    });
};

export const useSuperAdminAttributeRelations = () => {
    return useQuery({
        queryKey: SuperAdminAttributesQueryKeys.allRelations,
        queryFn: ({ signal }) => apiGet<AttributeRelation[]>(SUPERADMIN_ATTRIBUTES_RELATIONS_PATH, { signal }),
    });
};

export const useSuperAdminCreateAttributeRelation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (body: AttributeRelationCreateDto) => apiPost<AttributeRelation>(SUPERADMIN_ATTRIBUTES_RELATIONS_PATH, body),
        onSuccess: () => {
            queryClient.invalidateQueries(SuperAdminAttributesQueryKeys.allRelations);
        },
    });
};

export const useSuperAdminUpdateAttributeRelation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (body: AttributeRelationUpdateDto) => apiPatch<AttributeRelation>(SUPERADMIN_ATTRIBUTES_RELATIONS_PATH, body),
        onSuccess: () => {
            queryClient.invalidateQueries(SuperAdminAttributesQueryKeys.allRelations);
        },
    });
};

export const useSuperAdminDeleteAttributeRelation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (body: AttributeRelationDeleteDto) => apiDelete<BasicMessageDto>(SUPERADMIN_ATTRIBUTES_RELATIONS_PATH, body),
        onSuccess: () => {
            queryClient.invalidateQueries(SuperAdminAttributesQueryKeys.allRelations);
        },
    });
};

export const useSuperAdminOrderAttributeRelations = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: AttributeRelationOrder) =>
            apiPost<AttributeRelationOrder>(`${SUPERADMIN_ATTRIBUTES_RELATIONS_PATH}/change-order`, data),
        onSuccess: async () => {
            // await until invalidation has re-fetched the data, so component local onSuccess will be executed with updated data
            await queryClient.invalidateQueries(SuperAdminAttributesQueryKeys.allRelations);
        },
    });
};

export const useSuperAdminIdeaAttributeRelations = (clientId?: number | null) => {
    return useQuery({
        queryKey: SuperAdminAttributesQueryKeys.ideaRelationForId(clientId ?? 0),
        queryFn: ({ signal, queryKey }) =>
            apiGet<IdeaAttributeRelation[]>(`${SUPERADMIN_ATTRIBUTES_RELATIONS_IDEAS_PATH}/${queryKey[3]}`, { signal }),
        enabled: clientId != null,
    });
};

export const useSuperAdminCreateIdeaAttributeRelation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (body: IdeaAttributeRelationCreateDto) =>
            apiPost<IdeaAttributeRelation>(SUPERADMIN_ATTRIBUTES_RELATIONS_IDEAS_PATH, body),
        onSuccess: () => {
            queryClient.invalidateQueries(SuperAdminAttributesQueryKeys.allIdeaRelations);
        },
    });
};

export const useSuperAdminDeleteIdeaAttributeRelation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (body: IdeaAttributeRelationDeleteDto) => apiDelete<BasicMessageDto>(SUPERADMIN_ATTRIBUTES_RELATIONS_IDEAS_PATH, body),
        onSuccess: () => {
            queryClient.invalidateQueries(SuperAdminAttributesQueryKeys.allIdeaRelations);
        },
    });
};

export const useSuperAdminOrderIdeaAttributeRelations = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: IdeaAttributeRelationOrder) =>
            apiPost<BasicMessageDto>(`${SUPERADMIN_ATTRIBUTES_RELATIONS_IDEAS_PATH}/change-order`, data),
        onSuccess: async (_, data) => {
            // await until invalidation has re-fetched the data, so component local onSuccess will be executed with updated data
            await queryClient.invalidateQueries(SuperAdminAttributesQueryKeys.allIdeaRelations);
            await queryClient.invalidateQueries(SuperAdminAttributeCategoriesQueryKeys.forClient(data.clientId));
        },
    });
};
