import { TFunction } from "i18next";
import { useState } from "react";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import { UpdateClientInputDto } from "../../../domain/superadmin/clients";
import { translationKeys } from "../../../translations/main-translations";
import ClientDialog from "./ClientDialog";
import { IFlatClient } from "./ClientListTable";

interface EditClientDialogProps {
    open: boolean;
    onClose: () => void;
    client: IFlatClient;
    updateClient: (newClient: UpdateClientInputDto) => void;
    translate: TFunction;
}

const EditClientDialog = ({ client: clientProps, open, onClose, updateClient, translate }: EditClientDialogProps) => {
    // We want to have ALL fields in the new object and save this in the service as a 1:1 replacement!
    const [isValid, setIsValid] = useState(true);
    const [client, setClient] = useState<IFlatClient | null>(clientProps);

    const reset = () => {
        setIsValid(false);
        setClient(null);
    };

    const onSave = () => {
        if (isValid && client !== null) {
            updateClient({ clientId: clientProps.id, client });
            reset();
            onClose();
        }
    };

    const updatedClient = { ...clientProps, ...client };
    return (
        <ActionItemDialog
            open={open}
            onClose={onClose}
            action="edit"
            item="client"
            primary={translationKeys.VDLANG_SAVE}
            onPrimary={onSave}
            primaryDisabled={!isValid}
            translate={translate}
        >
            <ClientDialog
                client={updatedClient}
                updateClient={(client, isValid) => {
                    setIsValid(isValid);
                    setClient(client);
                }}
            />
        </ActionItemDialog>
    );
};

export default EditClientDialog;
