import { FormGroup, TextField } from "@mui/material";
import { ClientDto, SuperAdminTranslationDto } from "api-shared";
import React from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";
import { useSuperadminTranslations } from "../../../domain/superadmin/translations";
import { translationKeys } from "../../../translations/main-translations";

export type TranslationFormData = Pick<SuperAdminTranslationDto, "key" | "clientId" | "translationDe" | "translationEn">;

interface TranslationDialogProps {
    currentTranslationKey?: string;
    translation: TranslationFormData;
    clients: ClientDto[];
    updateTranslation: (editedObjects: Partial<TranslationFormData>, isValid: boolean) => void;
    onSave: () => void;
}

const TranslationDialog = ({ translation, clients, onSave, updateTranslation, currentTranslationKey }: TranslationDialogProps) => {
    const { t } = useTranslation();
    const translations = useSuperadminTranslations();

    if (translations.data === undefined) {
        return null;
    }

    const { key, clientId, translationEn, translationDe } = translation;

    const validateValue = (name: string | number | null) => name != null && (typeof name !== "string" || name.trim().length > 0);

    const validateTranslationKey = (value: string, clientId: number) => {
        const clientTranslationKeys = translations.data.filter((t) => t.clientId === clientId).map((t) => t.key.toLowerCase());
        // Lower-case comparisons since MySQL's unique constraints are case insensitive by default
        const inputValue = value.toLowerCase().trim();
        return currentTranslationKey
            ? !clientTranslationKeys.includes(inputValue) || inputValue === currentTranslationKey.toLowerCase()
            : !clientTranslationKeys.includes(inputValue);
    };

    const getValidationStatus = (name?: string, value?: string | number | null) => {
        const tempTranslation = {
            ...translation,
            ...(name !== undefined && value !== undefined && { [name]: value }),
        };
        return (
            Object.entries(tempTranslation).every(([property, value]) => {
                if (property === "key") {
                    return validateValue(value) && validateTranslationKey(String(value), clientId);
                }
                if (property === "clientId") {
                    return validateValue(value) && validateTranslationKey(key, Number(value));
                }
                return validateValue(value);
            }) && translation.clientId > 0
        );
    };

    const triggerSave = () => {
        onSave();
    };

    const onInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        updateTranslation({ [name]: value }, getValidationStatus(name, value));
    };

    const onSelectInputChanged = (option: Option<number> | null) => {
        const clientId = option !== null ? option.value : null;

        if (clientId != null) {
            updateTranslation({ clientId }, getValidationStatus("clientId", clientId));
        }
    };

    const options = clients.filter((client) => client.isCustomer).map((client) => ({ value: client.id, label: client.name }));

    const isValidTranslationKey = validateTranslationKey(key, clientId);

    return (
        <Form onSubmit={triggerSave}>
            <FormGroup>
                <TextField
                    required
                    label="Translation key"
                    autoFocus
                    name="key"
                    value={key}
                    onChange={onInputChanged}
                    error={!validateValue(key) || !isValidTranslationKey}
                    helperText={!isValidTranslationKey && t(translationKeys.VDLANG_SUPERADMIN_TRANSLATION_KEY_ALREADY_IN_USE)}
                    margin="normal"
                />
                <Select
                    label="Client"
                    value={options.find((t) => t.value === clientId)}
                    options={options}
                    onChange={onSelectInputChanged}
                    menuPortalTarget={document.body}
                    isSearchable
                    required
                />
                <TextField
                    required
                    name="translationEn"
                    label="English"
                    value={translationEn}
                    onChange={onInputChanged}
                    error={!validateValue(translationEn)}
                    margin="normal"
                />
                <TextField
                    required
                    name="translationDe"
                    label="German"
                    value={translationDe}
                    onChange={onInputChanged}
                    error={!validateValue(translationDe)}
                    margin="normal"
                />
            </FormGroup>
        </Form>
    );
};

export default TranslationDialog;
