import SuperAdminIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import { Card, CardContent, CardHeader, Chip, Stack, styled, Tooltip, Typography } from "@mui/material";
import React from "react";

const CRUDHeader = styled(CardHeader)(({ theme }) => ({
    position: "sticky",
    top: 0,
}));

interface CRUDContainerProps {
    title: string;
}

const SuperAdminContainer = ({ title, children }: React.PropsWithChildren<CRUDContainerProps>) => (
    <Card sx={{ height: "100%" }}>
        <CRUDHeader
            title={
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5">{title}</Typography>
                    <Tooltip title="With great power comes great responsibility." placement="left">
                        <Chip icon={<SuperAdminIcon />} color="error" label="Super Administration" sx={{ fontWeight: "bold" }} />
                    </Tooltip>
                </Stack>
            }
        />
        <CardContent sx={{ height: "100%", padding: 0 }}>{children}</CardContent>
    </Card>
);

export default SuperAdminContainer;
