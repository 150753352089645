import { DndContext, closestCenter } from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { ListItemText, Paper, Typography } from "@mui/material";
import { SuperAdminCustomValueDto } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import DndItem from "../../../components/draggable-list/DndItem";
import { useSuperAdminUpdateCustomValuesOrder } from "../../../domain/superadmin/custom-fields";
import useSortableList from "../../../hooks/useSortableList";
import { translationKeys } from "../../../translations/main-translations";

interface ICustomValueOrderDialogProps {
    activeCustomFieldId: number;
    customValues: SuperAdminCustomValueDto[];
    translate: TFunction;
    onClose: () => void;
}

const sortValues = (a: SuperAdminCustomValueDto, b: SuperAdminCustomValueDto): number => (a.order ?? 0) - (b.order ?? 0);

const CustomValueOrderDialog = ({ activeCustomFieldId, customValues, translate, onClose }: ICustomValueOrderDialogProps) => {
    const [reorderedRelations, setReorderedRelations] = useState<SuperAdminCustomValueDto[]>(
        customValues.filter((c) => c.measureAttributeId === activeCustomFieldId).toSorted(sortValues),
    );

    const updateCustomValueOrder = useSuperAdminUpdateCustomValuesOrder().mutate;

    const onSave = () => {
        if (reorderedRelations === undefined) {
            return;
        }
        const updatedOrder = reorderedRelations.map((relation, index) => ({ ...relation, order: index + 1 }));
        updateCustomValueOrder({ measureAttributeId: activeCustomFieldId, customValues: updatedOrder });
    };

    const DragAndDropModifiers = [restrictToVerticalAxis, restrictToFirstScrollableAncestor];

    const { sensors, onDragEnd, onDragStart } = useSortableList({ items: reorderedRelations, updateItems: setReorderedRelations });

    return (
        <ActionItemDialog
            open={true}
            onClose={onClose}
            action="edit"
            primary={translate(translationKeys.VDLANG_SAVE)}
            primaryIsTranslated
            item="customvalueorder"
            onPrimary={onSave}
            translate={translate}
        >
            <Paper>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    modifiers={DragAndDropModifiers}
                >
                    <SortableContext items={reorderedRelations} strategy={verticalListSortingStrategy}>
                        {reorderedRelations.map((item, index) => (
                            <DndItem key={item.id} id={item.id} divider showHandle>
                                <ListItemText>
                                    <Typography>{item.value}</Typography>
                                </ListItemText>
                            </DndItem>
                        ))}
                    </SortableContext>
                </DndContext>
            </Paper>
        </ActionItemDialog>
    );
};

export default CustomValueOrderDialog;
