import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Typography } from "@mui/material";
import { AttributeTarget, AttributeTitle, AttributeType, SuperAdminAttributeDto, SuperAdminMeasureConfigDto } from "api-shared";
import { FormEventHandler } from "react";
import { Control, Controller } from "react-hook-form";
import Form from "../../../components/Form";
import Tooltip from "../../../components/Tooltip";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";
import { FieldRelationFormData } from "./FieldRelationDialog";

interface IFieldRelationFormProps {
    measureConfig: SuperAdminMeasureConfigDto;
    onSubmit: FormEventHandler<HTMLFormElement>;
    control: Control<FieldRelationFormData>;
    target: AttributeTarget;
    attributes: SuperAdminAttributeDto[];
    isAttributeDisabled: boolean;
    attributeIdsInUse: number[];
    isMandatory: boolean;
}

const FieldRelationForm = ({
    control,
    attributes,
    attributeIdsInUse,
    target,
    measureConfig,
    onSubmit,
    isAttributeDisabled,
    isMandatory,
}: IFieldRelationFormProps) => {
    let assignableAttributes = attributes
        .filter(({ clientId }) => clientId === null || clientId === measureConfig.clientId)
        .filter(({ title }) => title !== AttributeTitle.OriginatorClient);

    if (target === AttributeTarget.EffectCategory) {
        // Only a specific set of attributes should be available in effect categories
        assignableAttributes = assignableAttributes
            .filter(({ type }) => type === AttributeType.Combobox || type === AttributeType.Tree)
            .filter(({ isMulti, isCreatable }) => !isMulti && !isCreatable);
    }

    const attributeOptions = assignableAttributes.map(({ id, title }) => ({ value: id, label: title }));

    const gateTaskConfigOptions = measureConfig.gateTaskConfigs.map(({ id, name }) => ({ id, label: name, value: id }) as Option);

    return (
        <Form onSubmit={onSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl>
                        <FormLabel sx={{ mb: 0.5 }}>Measure Config</FormLabel>
                        <Typography>{measureConfig.name}</Typography>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl>
                        <FormLabel sx={{ mb: 0.5 }}>Location</FormLabel>
                        <Typography>{target === AttributeTarget.EffectCategory ? "Effect-Category" : "Sidebar"}</Typography>
                    </FormControl>
                </Grid>
            </Grid>
            <Controller
                name="attributeId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                    const { value, onChange, ...inputProps } = field;
                    const availableOptions = attributeOptions.filter(
                        (o) => o.value === value || !attributeIdsInUse.some((id) => o.value === id),
                    );
                    const selectedOption = availableOptions.find((o) => o.value === value);

                    return (
                        <Select
                            value={selectedOption}
                            options={availableOptions}
                            onChange={(o) => onChange(o?.value ?? null)}
                            menuPortalTarget={document.body}
                            label="Attribute"
                            isDisabled={isAttributeDisabled}
                            {...inputProps}
                        />
                    );
                }}
            />
            <Controller
                name="mandatory"
                control={control}
                render={({ field }) => (
                    <Tooltip title={target === AttributeTarget.EffectCategory ? "EffectCategory attributes are always mandatory" : ""}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    disabled={target === AttributeTarget.EffectCategory}
                                    checked={field.value}
                                    {...field}
                                />
                            }
                            label="Mandatory"
                        />
                    </Tooltip>
                )}
            />
            {isMandatory && target !== AttributeTarget.EffectCategory && (
                <Controller
                    name="gateTaskConfig"
                    control={control}
                    render={({ field }) => {
                        const { value, onChange, ...inputProps } = field;
                        const selectedOption = gateTaskConfigOptions.find((o) => o.value === value);

                        return (
                            <Select
                                value={selectedOption}
                                options={gateTaskConfigOptions}
                                onChange={(o) => onChange(o?.value ?? null)}
                                menuPortalTarget={document.body}
                                label="Gate Task Config"
                                isClearable
                                {...inputProps}
                            />
                        );
                    }}
                />
            )}
        </Form>
    );
};

export default FieldRelationForm;
