import {
    useSuperAdminAttributeCategories,
    useSuperAdminCreateAttributeCategory,
    useSuperAdminUpdateAttributeCategory,
    useSuperAdminDeleteAttributeCategory,
    useSuperAdminChangeAttributeCategoriesOrder,
} from "../../../domain/superadmin/attribute-categories";
import { Card, CardActions, Button, List, ListItemText } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import SaveIcon from "@mui/icons-material/SaveRounded";
import useDialog from "../../../hooks/useDialog";
import { useState, useMemo } from "react";
import AttributeCategoryDialog, { AttributeCategoryForm } from "../attributes/AttributeCategoryDialog";
import AttributeListEntry from "../attributes/AttributeListEntry";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";
import { closestCenter, DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import useSortableList from "../../../hooks/useSortableList.ts";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { translateFromProperty } from "../../../lib/translate.ts";
import { useLanguage } from "../../../hooks/useLanguage.ts";
import { nonNullable } from "api-shared";
import LoadingAnimation from "../../../components/loading/LoadingAnimation.tsx";

type AttributeDndItem = { id: string; dataId: number; order: number; title: string; isFilled: boolean };

const DragAndDropModifiers = [restrictToVerticalAxis, restrictToFirstScrollableAncestor];

interface CustomFieldsCategoriesProps {
    clientId: number;
}

const CustomFieldsCategories = ({ clientId }: CustomFieldsCategoriesProps) => {
    const attributeCategoriesQuery = useSuperAdminAttributeCategories(clientId);

    const createCategoryMutation = useSuperAdminCreateAttributeCategory();
    const updateCategoryMutation = useSuperAdminUpdateAttributeCategory();
    const deleteCategoryMutation = useSuperAdminDeleteAttributeCategory();
    const changeCategoriesOrderMutation = useSuperAdminChangeAttributeCategoriesOrder();

    const [categoryToDelete, setCategoryToDelete] = useState<number>();
    const [categoryToEdit, setCategoryToEdit] = useState<number>();
    const [isEditingOrder, setIsEditingOrder] = useState(false);

    const language = useLanguage();
    const { t } = useTranslation();
    const categoryDialog = useDialog();

    const sortedCategories = useMemo(() => sortBy(attributeCategoriesQuery?.data ?? [], (c) => c.order), [attributeCategoriesQuery.data]);
    const [order, setOrder] = useState<number[]>([]);
    const displayOrder = order.length > 0 ? order : sortedCategories.map(({ id }) => id);

    const dndItems = displayOrder
        .map((id) => {
            const item = sortedCategories.find((item) => item.id === id);
            if (item !== undefined) {
                return {
                    id: `attributeCategory${id}`,
                    dataId: id,
                    isFilled: true,
                    order: item.order,
                    title: translateFromProperty(item, "name", language),
                };
            }
        })
        .filter(nonNullable);

    function updateDndItems(newItems: AttributeDndItem[]) {
        setOrder(newItems.map(({ dataId }) => dataId));
    }

    const { sensors, onDragEnd, onDragStart } = useSortableList({ items: dndItems, updateItems: updateDndItems });

    function openCategoryDialog(id: number) {
        setCategoryToEdit(id);
        categoryDialog.open();
    }

    function closeCategoryDialog() {
        categoryDialog.close();
        setCategoryToEdit(undefined);
    }

    function saveCategory({ nameDe, nameEn }: AttributeCategoryForm) {
        if (categoryToEdit !== undefined) {
            updateCategoryMutation.mutate({ id: categoryToEdit, clientId, nameDe, nameEn }, { onSuccess: () => closeCategoryDialog() });
        } else {
            createCategoryMutation.mutate({ clientId, nameDe, nameEn }, { onSuccess: () => closeCategoryDialog() });
        }
    }

    function deleteCategory() {
        if (categoryToDelete === undefined) {
            return;
        } else {
            deleteCategoryMutation.mutate({ id: categoryToDelete, clientId }, { onSuccess: () => setCategoryToDelete(undefined) });
        }
    }

    function saveOrder() {
        changeCategoriesOrderMutation.mutate({ clientId, order }, { onSuccess: () => setIsEditingOrder(false) });
    }

    if (!attributeCategoriesQuery.isSuccess) {
        return <LoadingAnimation sx={{ marginTop: 4 }} />;
    }

    return (
        <Card sx={{ border: "none" }}>
            <AttributeCategoryDialog
                key={JSON.stringify(categoryToEdit)}
                open={categoryDialog.isOpen}
                onClose={closeCategoryDialog}
                onSave={saveCategory}
                defaultValues={attributeCategoriesQuery.data?.find((category) => category.id === categoryToEdit)}
            />
            <DeleteDialog
                item="attributeCategory"
                open={categoryToDelete != null}
                onClose={() => setCategoryToDelete(undefined)}
                onDelete={deleteCategory}
                translate={t}
            />
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                modifiers={DragAndDropModifiers}
            >
                <SortableContext items={dndItems} strategy={verticalListSortingStrategy}>
                    <List sx={{ padding: 0 }}>
                        {dndItems.map((item) => (
                            <AttributeListEntry
                                key={item.id}
                                id={item.id}
                                isUsed={false}
                                isEditingOrder={isEditingOrder}
                                onDelete={() => setCategoryToDelete(item.dataId)}
                                onEdit={() => openCategoryDialog(item.dataId)}
                            >
                                <ListItemText>
                                    {item.title} (ID: {item.dataId})
                                </ListItemText>
                            </AttributeListEntry>
                        ))}
                    </List>
                </SortableContext>
            </DndContext>
            <CardActions>
                <Button startIcon={<AddRoundedIcon />} onClick={categoryDialog.open}>
                    Add Category
                </Button>
                {isEditingOrder ? (
                    <Button startIcon={<SaveIcon />} onClick={() => saveOrder()}>
                        Save order
                    </Button>
                ) : (
                    <Button startIcon={<EditIcon />} onClick={() => setIsEditingOrder(true)}>
                        Edit order
                    </Button>
                )}
            </CardActions>
        </Card>
    );
};

export default CustomFieldsCategories;
