import { ClientDto } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import { CreateTranslationInputDto } from "../../../domain/superadmin/translations";
import TranslationDialog, { TranslationFormData } from "./TranslationDialog";

interface CreateTranslationDialogProps {
    open: boolean;
    onClose: () => void;
    createTranslation: (data: CreateTranslationInputDto) => void;
    translate: TFunction;
    clients: ClientDto[];
}

const newTranslation: TranslationFormData = {
    key: "",
    clientId: 0,
    translationEn: "",
    translationDe: "",
};

const CreateTranslationDialog = ({ open, onClose, createTranslation, translate, clients }: CreateTranslationDialogProps) => {
    const [isValid, setIsValid] = useState(false);
    const [translation, setTranslation] = useState<TranslationFormData>(newTranslation);

    const updateTranslation = (changes: Partial<TranslationFormData>, isValid: boolean) => {
        setTranslation((old) => ({ ...old, ...changes }));
        setIsValid(isValid);
    };

    const close = () => {
        onClose();
        // keep clientId for adding multiple translations for the same client
        setTranslation((old) => ({ ...newTranslation, clientId: old.clientId }));
        setIsValid(false);
    };

    const submitAndClose = () => {
        if (!isValid) {
            return;
        }
        createTranslation(translation);
        close();
    };

    return (
        <ActionItemDialog
            open={open}
            onClose={close}
            action="create"
            item="translation"
            primary="create_translation"
            onPrimary={submitAndClose}
            primaryDisabled={!isValid}
            translate={translate}
        >
            <TranslationDialog translation={translation} updateTranslation={updateTranslation} onSave={submitAndClose} clients={clients} />
        </ActionItemDialog>
    );
};

export default CreateTranslationDialog;
