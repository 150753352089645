import { Button, Divider, Grid, Typography } from "@mui/material";
import { SuperAdminTranslationDto } from "api-shared";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import SearchInput from "../../../components/input/SearchInput";
import Select from "../../../components/input/select/Select.tsx";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import BaseTable from "../../../components/table/BaseTable";
import TableDeleteButton from "../../../components/table/TableDeleteButton";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableSettingsButton from "../../../components/table/TableSettingsButton";
import TableTextCell from "../../../components/table/TableTextCell";
import { useSuperadminCustomers } from "../../../domain/superadmin/clients";
import {
    useSuperadminCreateTranslation,
    useSuperadminDeleteTranslation,
    useSuperadminTranslations,
    useSuperadminUpdateTranslation,
} from "../../../domain/superadmin/translations";
import { useDebounce } from "../../../hooks/useDebounce";
import useDialog from "../../../hooks/useDialog";
import CreateTranslationDialog from "./CreateTranslationDialog";
import EditTranslationDialog from "./EditTranslationDialog";

const TranslationListTable = () => {
    const [selectedClientId, setSelectedClientId] = useState<number>();

    const clientsQuery = useSuperadminCustomers();
    const translationsQuery = useSuperadminTranslations(selectedClientId, selectedClientId !== undefined);
    const createTranslationMutation = useSuperadminCreateTranslation();
    const updateTranslationMutation = useSuperadminUpdateTranslation();
    const deleteTranslationMutation = useSuperadminDeleteTranslation();

    const { t: translate } = useTranslation();

    const createDialog = useDialog();
    const editDialog = useDialog();
    const deleteDialog = useDialog();

    const [selectedTranslationId, setSelectedTranslationId] = useState<number | null>(null);
    const [filter, setFilter] = useState("");
    const debouncedFilter = useDebounce(filter);

    const openEditDialog = editDialog.open;
    const openEditTranslationForm = useCallback(
        (translationId: number) => {
            openEditDialog();
            setSelectedTranslationId(translationId);
        },
        [openEditDialog],
    );

    const clientOptions =
        clientsQuery.data?.map((client) => {
            return { value: client.id, label: client.name };
        }) ?? [];
    const selectedClientOption = clientOptions.find(({ value }) => value === selectedClientId);

    const openDeleteDialog = deleteDialog.open;
    const openDeleteTranslationForm = useCallback(
        (translationId: number) => {
            openDeleteDialog();
            setSelectedTranslationId(translationId);
        },
        [openDeleteDialog],
    );

    const openCreateTranslationForm = () => {
        createDialog.open();
    };

    const columns = useMemo<Column<SuperAdminTranslationDto>[]>(
        () => [
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Id",
                accessor: "id",
                width: 48,
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Translation key",
                accessor: "key",
                width: 80,
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "Client",
                id: "client",
                accessor: ({ clientId }) => (clientsQuery.data ?? []).find((client) => client.id === clientId)?.name ?? "",
                width: 120,
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "English translation",
                accessor: "translationEn",
            },
            {
                Header: TableHeaderCell,
                Cell: TableTextCell,
                label: "German translation",
                accessor: "translationDe",
            },
            {
                Header: TableHeaderCell,
                Cell: ({ value }) => (
                    <TableSettingsButton title={translate("edit_translation")} onClick={() => openEditTranslationForm(value)} />
                ),
                label: "",
                accessor: "id",
                id: "OPTIONS",
                width: 64,
            },
            {
                Header: TableHeaderCell,
                Cell: ({ value }) => (
                    <TableDeleteButton title={translate("delete_translation")} onClick={() => openDeleteTranslationForm(value)} />
                ),
                label: "",
                accessor: "id",
                id: "DELETE",
                width: 64,
            },
        ],
        [translate, openDeleteTranslationForm, openEditTranslationForm, clientsQuery.data],
    );

    if (!clientsQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const selectedTranslation = translationsQuery.data?.find(({ id }) => id === selectedTranslationId);

    return (
        <>
            <Divider />

            <Grid container p={2} justifyContent="space-between" alignItems="flex-end">
                <Grid item xs={3}>
                    <Select
                        margin="none"
                        value={selectedClientOption}
                        options={clientOptions}
                        onChange={(newValue) => {
                            setSelectedClientId(newValue?.value);
                            setFilter("");
                        }}
                        label="Select Client"
                        menuPortalTarget={document.body}
                        isSearchable
                        required
                        isMulti={false}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={openCreateTranslationForm}>
                        {translate("create_translation")}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <SearchInput translate={translate} onChange={setFilter} searchKey={filter} />
                </Grid>
            </Grid>

            <Divider />

            {selectedClientId !== undefined && translationsQuery.isSuccess ? (
                <BaseTable
                    noDataText="No translation data"
                    data={translationsQuery.data}
                    columns={columns}
                    translate={translate}
                    globalFilter={debouncedFilter}
                />
            ) : (
                <Typography variant="subtitle2" color="primary" mt={2} px={2}>
                    Select Client to show existing Custom Translations
                </Typography>
            )}

            <DeleteDialog
                open={deleteDialog.isOpen}
                onClose={deleteDialog.close}
                key={`delete_${String(selectedTranslationId)}`}
                translate={translate}
                onDelete={() => selectedTranslationId != null && deleteTranslationMutation.mutate(selectedTranslationId)}
                item="translation"
            />
            {selectedTranslation !== undefined ? (
                <EditTranslationDialog
                    open={editDialog.isOpen}
                    onClose={editDialog.close}
                    translation={selectedTranslation}
                    key={`edit_${selectedTranslation.id}`}
                    updateTranslation={updateTranslationMutation.mutate}
                    translate={translate}
                    clients={clientsQuery.data}
                />
            ) : null}
            <CreateTranslationDialog
                open={createDialog.isOpen}
                onClose={createDialog.close}
                createTranslation={createTranslationMutation.mutate}
                translate={translate}
                clients={clientsQuery.data}
            />
        </>
    );
};

export default TranslationListTable;
