import { Button, Divider, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { AttributeTable, CustomFieldsOrderType, SuperAdminTranslationDto, mergeCamelized } from "api-shared";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useSuperadminCustomers } from "../../../domain/superadmin/clients";
import {
    useSuperAdminCreateCustomField,
    useSuperAdminCreateCustomValue,
    useSuperAdminCustomFields,
    useSuperAdminCustomValues,
    useSuperAdminDeleteCustomField,
    useSuperAdminDeleteCustomValue,
    useSuperAdminUpdateCustomField,
    useSuperAdminUpdateCustomValue,
} from "../../../domain/superadmin/custom-fields";
import {
    useSuperadminCreateTranslation,
    useSuperadminDeleteTranslation,
    useSuperadminTranslations,
    useSuperadminUpdateTranslation,
} from "../../../domain/superadmin/translations";
import SuperAdminContainer from "../SuperAdminContainer.tsx";
import CustomFieldsTable, { ICustomField } from "./CustomFieldsTable";
import CustomValueOrderDialog from "./CustomValueOrderDialog";
import CustomValuesTable, { ICustomValue } from "./CustomValuesTable";
import CustomFieldsCategories from "./CustomFieldsCategories";

/**
 * Build translation keys for field and hint.
 * @param name
 * @returns Tuple: first is measureAttribute.title, second is for hint.
 */
const buildTranslationKeys = (name: string) => {
    return [name, mergeCamelized(name, "hint")] as const;
};

const TABS = ["Fields", "Values", "Categories"];

export enum OrderType {
    Measure,
    Postcapture,
    CustomValue,
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: Readonly<TabPanelProps>) {
    const { children, value, index, ...other } = props;

    return (
        <div hidden={value !== index} {...other}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

const CustomFields = () => {
    const { t: translate } = useTranslation();

    const [activeTab, setActiveTab] = useState(0);
    const [activeClient, setActiveClient] = useState<number | null>();
    const [customFieldToDelete, setCustomFieldToDelete] = useState<number | null>(null);
    const [customValueToDelete, setCustomValueToDelete] = useState<number | null>(null);
    const [activeCustomFieldId, setActiveCustomFieldId] = useState<number>(0);
    const [orderType, setOrderType] = useState<CustomFieldsOrderType | null>(null);

    const clients = useSuperadminCustomers();

    const translations = useSuperadminTranslations(activeClient, activeClient != null);
    const deleteSuperAdminTranslation = useSuperadminDeleteTranslation().mutate;
    const updateSuperAdminTranslation = useSuperadminUpdateTranslation().mutate;
    const createSuperAdminTranslation = useSuperadminCreateTranslation().mutate;

    const customFields = useSuperAdminCustomFields(activeClient, activeClient !== undefined);
    const customValues = useSuperAdminCustomValues(activeClient, activeClient !== undefined);

    const createSuperAdminCustomField = useSuperAdminCreateCustomField().mutate;
    const createSuperAdminCustomValue = useSuperAdminCreateCustomValue().mutate;

    const updateSuperAdminCustomField = useSuperAdminUpdateCustomField().mutate;
    const updateSuperAdminCustomValue = useSuperAdminUpdateCustomValue().mutate;

    const deleteSuperAdminCustomField = useSuperAdminDeleteCustomField().mutate;
    const deleteSuperAdminCustomValue = useSuperAdminDeleteCustomValue().mutate;

    const handleChangeClient = (option: Option<number | null | undefined> | null) => {
        if (option !== null) {
            setActiveClient(option.value);
            setActiveCustomFieldId(0);
        }
    };

    const handleChangeCustomValueField = (option: Option<number> | null) => setActiveCustomFieldId(option === null ? 0 : option.value);

    const createUpdateOrDeleteTranslation = (clientId: number | null, name: string, textEn: string, textDe: string) => {
        const translation = translations.data?.find((translation: SuperAdminTranslationDto) => translation.key === name);
        if (translation && !textEn && !textDe) {
            deleteSuperAdminTranslation(translation.id);
        } else if (translation) {
            updateSuperAdminTranslation({
                translationId: translation.id,
                translation: { translationEn: textEn, translationDe: textDe },
            });
        } else if (!translation && textEn && textDe) {
            createSuperAdminTranslation({
                key: name,
                clientId: clientId,
                translationEn: textEn,
                translationDe: textDe,
            });
        }
    };

    const createCustomField = (customfield: ICustomField) => {
        createSuperAdminCustomField(customfield);
        const [fieldKey, hintKey] = buildTranslationKeys(customfield.title);
        createSuperAdminTranslation({
            key: fieldKey,
            clientId: customfield.clientId,
            translationEn: customfield.translationEn,
            translationDe: customfield.translationDe,
        });
        if (customfield.hintEn || customfield.hintDe) {
            createSuperAdminTranslation({
                key: hintKey,
                clientId: customfield.clientId,
                translationEn: customfield.hintEn,
                translationDe: customfield.hintDe,
            });
        }
    };

    const updateCustomField = (customFieldUpdate: ICustomField) => {
        const customField = customFields.data?.find((cf) => cf.id === customFieldUpdate.id);
        if (customField === undefined) {
            return;
        }

        if (!customField.isFilled || (!customField.isMulti && !customField.isUsedInEffectCategory)) {
            updateSuperAdminCustomField({ id: customField.id, field: customFieldUpdate });
        }

        const [fieldKey, hintKey] = buildTranslationKeys(customFieldUpdate.title);
        createUpdateOrDeleteTranslation(
            customFieldUpdate.clientId,
            fieldKey,
            customFieldUpdate.translationEn,
            customFieldUpdate.translationDe,
        );
        createUpdateOrDeleteTranslation(customFieldUpdate.clientId, hintKey, customFieldUpdate.hintEn, customFieldUpdate.hintDe);
    };

    const deleteCustomField = (customFieldId: number) => {
        deleteSuperAdminCustomField(customFieldId);
        const customField = customFields?.data?.find((customField) => customField.id === customFieldId);
        if (customField) {
            const keys = buildTranslationKeys(customField.title);
            const translationsToDelete = translations.data?.filter((translation: SuperAdminTranslationDto) =>
                keys.includes(translation.key),
            );
            translationsToDelete?.forEach((translation: SuperAdminTranslationDto) => {
                deleteSuperAdminTranslation(translation.id);
            });
        }
    };

    const createCustomValue = (customValue: ICustomValue) => {
        customValue.measureAttributeId = activeCustomFieldId;
        createSuperAdminCustomValue(customValue);
        if (customValue.translationEn || customValue.translationDe) {
            const customField = customFields?.data?.find((customField) => customField.id === activeCustomFieldId);
            if (customField) {
                createUpdateOrDeleteTranslation(
                    customField.clientId,
                    customValue.value,
                    customValue.translationEn,
                    customValue.translationDe,
                );
            }
        }
    };

    const updateCustomValue = (customValue: ICustomValue) => {
        updateSuperAdminCustomValue({ id: customValue.id, value: customValue });
        const customValueField = customFields?.data?.find((customField) => customField.id === activeCustomFieldId);
        if (customValueField) {
            createUpdateOrDeleteTranslation(
                customValueField.clientId,
                customValue.value,
                customValue.translationEn,
                customValue.translationDe,
            );
        }
    };

    const deleteCustomValue = (customValueId: number) => {
        deleteSuperAdminCustomValue(customValueId);
        const customValue = customValues.data?.find((customValue) => customValue.id === customValueId);
        if (customValue) {
            createUpdateOrDeleteTranslation(null, customValue.value, "", "");
        }
    };

    if (!clients.isSuccess) {
        return <LoadingAnimation />;
    }

    let clientOptions: Option<number | undefined | null>[] = clients.data.map(({ id, name }: { id?: number | null; name: string }) => ({
        value: id,
        label: name,
    }));
    clientOptions = [{ value: undefined, label: "Select Client..." }, { value: null, label: "Global" }, ...clientOptions];

    // Global fields should not be shown in table, but are required for some resolving
    const clientFields = customFields.data?.filter((customField) => customField.clientId !== null) ?? [];

    const customValueFields = activeClient != null ? clientFields : (customFields.data ?? []);

    let customValueFieldsOptions = customValueFields
        .filter((customValueField) => customValueField.tableName === AttributeTable.CustomValues)
        .map(({ id, title }: { id: number; title: string }) => ({
            value: id,
            label: title,
        }));
    customValueFieldsOptions = [{ value: 0, label: "Select Custom Field..." }, ...customValueFieldsOptions];

    const customFieldValues =
        activeCustomFieldId !== 0 && customValues.isSuccess
            ? customValues.data?.filter((customValue) => customValue.measureAttributeId === activeCustomFieldId)
            : [];

    return (
        <SuperAdminContainer title="Custom Fields">
            <Divider />

            <Stack>
                <Tabs value={activeTab} onChange={(e, v) => setActiveTab(v)}>
                    {TABS.map((label, index) => (
                        <Tab key={index} label={label} />
                    ))}
                </Tabs>
                <Divider />
                <Stack p={2}>
                    <Select
                        label="Client"
                        value={clientOptions.find((t) => t.value === activeClient)}
                        options={clientOptions}
                        onChange={handleChangeClient}
                        menuPortalTarget={document.body}
                        margin="none"
                        required
                    />
                </Stack>
            </Stack>

            <Divider />

            <Stack>
                <TabPanel value={activeTab} index={0}>
                    {activeClient !== undefined ? (
                        <CustomFieldsTable
                            clientId={activeClient}
                            items={activeClient != null ? clientFields : (customFields.data ?? [])}
                            translations={translations.data ?? []}
                            translate={translate}
                            createCustomField={createCustomField}
                            updateCustomField={updateCustomField}
                            deleteCustomField={setCustomFieldToDelete}
                        />
                    ) : (
                        <Stack p={2}>
                            <Typography variant="subtitle2" color="primary" mt={2}>
                                Select Client to show existing Custom Fields
                            </Typography>
                        </Stack>
                    )}
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <Grid container spacing={1} padding={2} alignItems="flex-end">
                        <Grid item xs={10}>
                            <Select
                                fullWidth
                                margin="none"
                                label="Custom Field"
                                value={customValueFieldsOptions.find((t) => t.value === activeCustomFieldId)}
                                options={customValueFieldsOptions}
                                onChange={handleChangeCustomValueField}
                                menuPortalTarget={document.body}
                                required
                                isDisabled={activeClient === 0}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                fullWidth
                                disabled={activeCustomFieldId === 0}
                                variant="contained"
                                color="primary"
                                onClick={() => setOrderType(CustomFieldsOrderType.CUSTOMVALUE)}
                            >
                                Change Order
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider />
                    {activeCustomFieldId > 0 ? (
                        <CustomValuesTable
                            customFieldId={activeCustomFieldId}
                            customValues={customFieldValues}
                            customFields={customValueFields}
                            translations={translations.data ?? []}
                            translate={translate}
                            createCustomValue={createCustomValue}
                            updateCustomValue={updateCustomValue}
                            deleteCustomValue={setCustomValueToDelete}
                        />
                    ) : (
                        <Stack p={2}>
                            <Typography variant="subtitle2" color="primary" mt={2}>
                                Select Client and Custom Field to show existing Custom Fields
                            </Typography>
                        </Stack>
                    )}
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    {activeClient == null ? (
                        <Stack p={2}>
                            <Typography variant="subtitle2" color="primary" mt={2}>
                                Select Client to show existing Categories
                            </Typography>
                        </Stack>
                    ) : (
                        <CustomFieldsCategories clientId={activeClient} />
                    )}
                </TabPanel>
            </Stack>

            {customFieldToDelete !== null && (
                <DeleteDialog
                    open={Boolean(customFieldToDelete)}
                    translate={translate}
                    onClose={() => setCustomFieldToDelete(null)}
                    onDelete={() => customFieldToDelete != null && deleteCustomField(customFieldToDelete)}
                    item="customfield"
                >
                    {translate("remove_customfield_description", {
                        customField: clientFields.find((c) => c.id === customFieldToDelete)?.title ?? "",
                    })}
                </DeleteDialog>
            )}
            {customValues.isSuccess && customValueToDelete !== null && (
                <DeleteDialog
                    open={Boolean(customValueToDelete)}
                    translate={translate}
                    onClose={() => setCustomValueToDelete(null)}
                    onDelete={() => customValueToDelete != null && deleteCustomValue(customValueToDelete)}
                    item="customvalue"
                >
                    {translate("remove_customvalue_description", {
                        customValue: customValues.data.find((c) => c.id === customValueToDelete)?.value ?? "",
                    })}
                </DeleteDialog>
            )}
            {orderType === CustomFieldsOrderType.CUSTOMVALUE && (
                <CustomValueOrderDialog
                    activeCustomFieldId={activeCustomFieldId}
                    customValues={customValues.data ?? []}
                    translate={translate}
                    onClose={() => setOrderType(null)}
                />
            )}
        </SuperAdminContainer>
    );
};

export default CustomFields;
