import { Divider, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useSuperAdminAttributes, useSuperAdminIdeaAttributeRelations } from "../../../domain/superadmin/attributes";
import { useSuperadminCustomers } from "../../../domain/superadmin/clients";
import SuperAdminContainer from "../SuperAdminContainer";
import FieldRelationCardIdeas from "./IdeaFieldRelationCard";

const IdeaFieldRelations = () => {
    const [activeClient, setActiveClient] = useState<number>();

    const clients = useSuperadminCustomers();
    const attributes = useSuperAdminAttributes(activeClient, activeClient !== undefined);
    const attributeRelations = useSuperAdminIdeaAttributeRelations(activeClient);

    const handleChangeClient = (option: Option<number> | null) => {
        setActiveClient(option?.value);
    };

    if (!clients.isSuccess) {
        return <LoadingAnimation />;
    }

    const clientOptions = clients.data.map(({ id, name }) => ({ value: id, label: name }));

    const clientSpecificAttributeRelations = attributeRelations?.data?.filter(({ clientId }) => clientId === activeClient) ?? [];

    const attributeIdsInUse = clientSpecificAttributeRelations.map(({ attributeId }) => attributeId);

    return (
        <>
            <SuperAdminContainer title="Opportunity Configuration">
                <Divider />

                <Grid container p={2}>
                    <Grid item xs={12}>
                        <Select
                            margin="none"
                            label="Client *"
                            value={clientOptions.find((t) => t.value === activeClient)}
                            options={clientOptions}
                            onChange={handleChangeClient}
                            menuPortalTarget={document.body}
                            isClearable
                        />
                    </Grid>
                </Grid>
            </SuperAdminContainer>

            <Grid container mt={1}>
                {activeClient == null ? (
                    <Grid item xs={12}>
                        <Paper>
                            <Typography variant="subtitle2" color="primary" p={2}>
                                Select Client to show existing Attribute Relations for opportunities
                            </Typography>
                        </Paper>
                    </Grid>
                ) : null}
                {attributes.isSuccess && activeClient != null ? (
                    <Grid item xs={12}>
                        <FieldRelationCardIdeas
                            clientId={activeClient}
                            attributes={attributes.data}
                            attributeIdsInUse={attributeIdsInUse}
                            relations={clientSpecificAttributeRelations}
                            title="Opportunity fields"
                            key={activeClient}
                        />
                    </Grid>
                ) : null}
            </Grid>
        </>
    );
};

export default IdeaFieldRelations;
