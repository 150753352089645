import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { ClientDto, SuperAdminClientListDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { translationKeys } from "../../translations/main-translations";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const SUPERADMIN_CLIENT_PATH = "superadmin/clients";

type InputClientDto = Omit<
    ClientDto,
    "createdAt" | "updatedAt" | "currencies" | "logoHash" | "internalContactId" | "dashboardColors" | "whiteSpotColor"
>;

export interface CreateClientClientDto extends Omit<InputClientDto, "id"> {
    currencyName: string;
    currencyIsoCode: string;
}

export type CreateClientUserDto = {
    createInitialUser: boolean;
    email?: string;
    realname?: string;
    password?: string;
};

export interface CreateClientInputDto {
    newClient: CreateClientClientDto;
    initialUser: CreateClientUserDto;
}

export interface UpdateClientInputDto {
    clientId: number;
    client: InputClientDto;
}

interface MergeClientsInputDto {
    primary: number;
    secondary: number;
}

export const useSuperadminClients = (options?: UseQueryOptions<SuperAdminClientListDto>) => {
    return useQuery({
        queryKey: [SUPERADMIN_CLIENT_PATH],
        queryFn: ({ signal }) => apiGet<SuperAdminClientListDto>(SUPERADMIN_CLIENT_PATH, { signal }),
        ...options,
    });
};

export const useSuperadminCustomers = () => {
    return useQuery({
        queryKey: [SUPERADMIN_CLIENT_PATH, "customers"],
        queryFn: ({ signal }) => apiGet<SuperAdminClientListDto>(`${SUPERADMIN_CLIENT_PATH}?onlyCustomers=1`, { signal }),
    });
};

export const useSuperadminCreateClient = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CreateClientInputDto) => apiPost<ClientDto>(SUPERADMIN_CLIENT_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_CLIENT_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CLIENT_CREATED));
        },
    });
};

export const useSuperadminUpdateClient = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: UpdateClientInputDto) => apiPatch<ClientDto>(`${SUPERADMIN_CLIENT_PATH}/${data.clientId}`, data.client),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_CLIENT_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CLIENT_SAVED));
        },
    });
};

export const useSuperadminMergeClients = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: MergeClientsInputDto) => apiPost<boolean>(`${SUPERADMIN_CLIENT_PATH}/merge`, data),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_CLIENT_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, translationKeys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_SUCCESS));
        },
    });
};
