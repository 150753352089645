import {
    ChangeSet,
    Column,
    EditingState,
    Filter,
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    Sorting,
    SortingState,
} from "@devexpress/dx-react-grid";
import {
    Grid as DxGrid,
    PagingPanel,
    Table,
    TableEditColumn,
    TableEditRow,
    TableFilterRow,
    TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import { SuperAdminAttributeDto, SuperAdminCustomValueDto, SuperAdminTranslationDto, TranslationType } from "api-shared";
import { TFunction } from "i18next";
import { isEmpty, isEqual } from "lodash";
import React, { ReactElement, useMemo, useState } from "react";
import DataGridCommandCell, { IDataGridCommandCellProps } from "../../../components/datagrid/DataGridCommandCell";
import DataGridNoDataComponent from "../../../components/datagrid/DataGridNoDataComponent";
import CustomPagingPanelContainer from "../../../components/datagrid/DataGridPaginationPane";
import { translationKeys } from "../../../translations/main-translations";

export interface ICustomValue extends SuperAdminCustomValueDto {
    translationEn: string;
    translationDe: string;
}

interface ICustomValuesTableProps {
    customFieldId: number;
    customValues: SuperAdminCustomValueDto[];
    customFields: SuperAdminAttributeDto[];
    translations: SuperAdminTranslationDto[];
    translate: TFunction;
    createCustomValue: (customValue: ICustomValue) => void;
    updateCustomValue: (customValue: ICustomValue) => void;
    deleteCustomValue: (customValueId: number) => void;
}

const CustomValuesTable = ({
    customFieldId,
    customValues,
    customFields,
    translations,
    translate,
    createCustomValue,
    updateCustomValue,
    deleteCustomValue,
}: ICustomValuesTableProps) => {
    const defaultColumns: Column[] = [
        { name: "value", title: "Name (internal)" },
        { name: "translationEn", title: "Value (EN)" },
        { name: "translationDe", title: "Value (DE)" },
    ];

    const [sorting, setSorting] = useState<Sorting[]>([{ columnName: defaultColumns[0].title ?? "", direction: "asc" }]);
    const [filters, setFilters] = useState<Filter[]>([]);
    const [invalidRowIds, setInvalidRowIds] = useState<number[]>([]);

    const injectDisabledState = (child: ReactElement<IDataGridCommandCellProps>, row: Record<string, unknown>) => {
        let isDisabled = false;

        if (child?.props.id === "commit") {
            const customField: SuperAdminAttributeDto | undefined = customFields.find((c) => c.id === customFieldId);
            if (customField?.translate === TranslationType.MapAndTranslate) {
                // If 'Translate values' was checked for the field, require both translation fields to be filled
                isDisabled =
                    isEmpty(row.value) ||
                    isEmpty(row.translationDe) ||
                    isEmpty(row.translationEn) ||
                    // Relevant when editing existing rows
                    invalidRowIds.includes(row.id as number);
            } else {
                isDisabled = isEmpty(row.value);
            }
        } else if (child?.props.id === "delete") {
            isDisabled = Boolean(row.isUsed);
        }

        if (isDisabled) {
            return React.cloneElement(child, { disabled: true });
        }

        return child;
    };

    const getNameTranslation = (row: SuperAdminCustomValueDto, columnName: string) => {
        const t = translations.find((t) => t.key === row.value);
        return t ? t[columnName as keyof typeof t] : "";
    };

    const commitChanges = ({ added, changed, deleted }: ChangeSet) => {
        if (added) {
            createCustomValue(added[0]);
        } else if (changed) {
            const row = customValues.find((customValue) => changed[customValue.id] != null);
            if (row) {
                const customValue: ICustomValue = { ...row, ...changed[row.id] };
                updateCustomValue(customValue);
            }
        } else if (deleted) {
            deleted.forEach((id) => deleteCustomValue(+id));
        }
    };

    const getRowsWithMissingTranslationValues = (changedRows: Record<string, Record<string, string>>) => {
        const translationFieldNames = ["translationEn", "translationDe"];
        return Object.keys(changedRows)
            .filter((rowKey) => {
                const currentRow = changedRows[rowKey];
                return Object.keys(currentRow).some(
                    (fieldName) => translationFieldNames.includes(fieldName) && isEmpty(currentRow[fieldName]),
                );
            })
            .map((rowId) => Number(rowId));
    };

    const rowsChangesChange = (changedRows: Record<string, Record<string, string>>) => {
        const updatedInvalidRowIds = getRowsWithMissingTranslationValues(changedRows);
        setInvalidRowIds((prevState) => (isEqual(prevState, updatedInvalidRowIds) ? prevState : updatedInvalidRowIds));
    };

    const rowPerPageText = useMemo(() => ({ rowsPerPage: translate(translationKeys.VDLANG_PAGING_ROWS_PER_PAGE) }), [translate]);

    const TableEditRowCell = ({ column, editingEnabled, row, ...remainder }: TableEditRow.CellProps) => {
        const { tableRow } = remainder;
        const customField: SuperAdminAttributeDto | undefined = customFields.find((c) => c.id === customFieldId);
        const isTranslated = customField?.options != null && customField.translate !== TranslationType.Map;
        // changing the value/key is prohibited, if customValue can be translated
        const enableValueEditing = column.name === "value" && (!isTranslated || tableRow.type === TableEditRow.ADDED_ROW_TYPE);
        const enableTranslationEditing = column.name !== "value" && isTranslated;
        const enableEditing = enableValueEditing || enableTranslationEditing;
        return <TableEditRow.Cell editingEnabled={enableEditing} column={column} row={row} {...remainder} />;
    };

    const TableEditColumnCell = ({ children, ...props }: TableEditColumn.CellProps) => {
        return (
            <TableEditColumn.Cell {...props}>
                {React.Children.map(children as ReactElement<IDataGridCommandCellProps>[], (child) =>
                    injectDisabledState(child, props.row),
                )}
            </TableEditColumn.Cell>
        );
    };

    const addCommand = customFieldId > 0 ? { showAddCommand: true } : "";

    const translatedCustomValues = customValues.map((customValue) => ({
        ...customValue,
        translationEn: getNameTranslation(customValue, "translationEn"),
        translationDe: getNameTranslation(customValue, "translationDe"),
    }));

    return (
        <DxGrid rows={translatedCustomValues} columns={defaultColumns} getRowId={(r) => r.id}>
            {/* Order of plugins is important!
                https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/plugin-overview#plugin-order */}
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <FilteringState filters={filters} onFiltersChange={setFilters} />
            <PagingState defaultCurrentPage={0} defaultPageSize={25} />
            <EditingState onCommitChanges={commitChanges} onRowChangesChange={rowsChangesChange} />

            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />

            <Table noDataCellComponent={DataGridNoDataComponent} />

            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <TableEditRow cellComponent={TableEditRowCell} />
            <TableEditColumn
                {...addCommand}
                showEditCommand
                showDeleteCommand
                commandComponent={DataGridCommandCell}
                cellComponent={TableEditColumnCell}
            />

            <PagingPanel containerComponent={CustomPagingPanelContainer} pageSizes={[10, 25, 50]} messages={rowPerPageText} />
        </DxGrid>
    );
};

export default CustomValuesTable;
