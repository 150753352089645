import { Button, Divider, Stack } from "@mui/material";
import { TFunction } from "i18next";
import { useCallback, useState } from "react";
import { Column } from "react-table";
import ActionItemDialog from "../../components/dialogues/ActionItemDialog";
import DeleteDialog from "../../components/dialogues/DeleteDialog";
import SearchInput from "../../components/input/SearchInput";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { IBaseTableProps } from "../../components/table/BaseTable";
import { useDebounce } from "../../hooks/useDebounce";
import useDialog from "../../hooks/useDialog";
import CRUDTable from "./CRUDTable";

export type Data = object; // allow any non-primitive type
export type UpdateData<T extends Data> = Partial<T>;

export type DataWithId<S> = { id: number } & S;

export type CRUDFormProps<T extends Data> = {
    onSave: () => void;
    item: T;
    translate: TFunction;
    updateItem: (item: T) => void;
    isValid: boolean;
    onValidChanged: (newValid: boolean) => void;
    items?: DataWithId<T>[];
};

// Use type instead of interface here, so keep the generics. Interfaces need to have statically known members, which would not work with
// Omit here
type CRUDListProps<T extends Data> = Omit<IBaseTableProps<DataWithId<T>>, "data"> & {
    columns: Column<DataWithId<T>>[];
    FormComponent: React.ComponentType<CRUDFormProps<T>>;
    itemName: string;
    translate: TFunction;
    items: DataWithId<T>[] | undefined;
    createItem?: (item: T) => void;
    updateItem?: (data: { id: number } & Partial<T>) => void;
    deleteItem?: (id: number) => void;
    itemFactory: (init?: Partial<T>) => T;
};

function CRUDList<T extends Data>({
    columns,
    FormComponent,
    itemName,
    translate,
    items,
    createItem,
    updateItem,
    deleteItem,
    itemFactory,
    ...tableProps
}: CRUDListProps<T>) {
    const [filter, setFilter] = useState("");
    const debouncedFilter = useDebounce(filter);

    const [itemToDelete, setItemToDelete] = useState<number | null>(null);
    const deleteDialog = useDialog();

    const createEditDialog = useDialog();
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const [isPendingItemValid, setIsPendingItemValid] = useState(false);

    const isCreateModal = selectedItemId === null;
    const selectedItem = items?.find((i) => i.id === selectedItemId);

    const canCreate = typeof createItem === "function";
    const canUpdate = typeof updateItem === "function";
    const canDelete = typeof deleteItem === "function";

    const [pendingItem, setPendingItem] = useState<T>(selectedItem ?? itemFactory());
    const updatePendingItem = (changes: T) => setPendingItem((old) => ({ ...old, ...changes }));

    const openDelete = deleteDialog.open;
    const openDeleteDialog = useCallback(
        (id: number) => {
            openDelete();
            setItemToDelete(id);
        },
        [openDelete],
    );

    const openCreateEdit = createEditDialog.open;
    const openCreateEditDialog = useCallback(
        (id?: number) => {
            setSelectedItemId(id ?? null);
            const newItem = (items ?? []).find((i) => i.id === id);
            setPendingItem(newItem ?? itemFactory());
            setIsPendingItemValid(newItem !== undefined);
            openCreateEdit();
        },
        [itemFactory, items, openCreateEdit],
    );

    const closeCreateEditDialog = () => {
        setSelectedItemId(null);
        setPendingItem(itemFactory());
        setIsPendingItemValid(false);
        createEditDialog.close();
    };

    const saveItem = () => {
        if (!isPendingItemValid || pendingItem === null) {
            return;
        }
        if (isCreateModal) {
            canCreate && createItem(pendingItem);
        } else if (canUpdate && selectedItemId !== null) {
            updateItem({ id: selectedItemId, ...pendingItem });
        }
        closeCreateEditDialog();
    };

    const handleDelete = () => {
        if (canDelete && itemToDelete != null) {
            deleteItem(itemToDelete);
        }
    };

    if (items == null) {
        return <LoadingAnimation />;
    }

    return (
        <Stack>
            {canDelete ? (
                <DeleteDialog
                    open={deleteDialog.isOpen}
                    onClose={deleteDialog.close}
                    onDelete={handleDelete}
                    item={itemName}
                    translate={translate}
                    hideDescription
                />
            ) : null}
            {canCreate || canUpdate ? (
                <ActionItemDialog
                    open={createEditDialog.isOpen}
                    onClose={closeCreateEditDialog}
                    action={isCreateModal ? "create" : "edit"}
                    item={itemName}
                    primary={`${isCreateModal ? "create" : "save"}_${itemName}`}
                    onPrimary={saveItem}
                    primaryDisabled={!isPendingItemValid}
                    translate={translate}
                >
                    <FormComponent
                        item={pendingItem}
                        updateItem={updatePendingItem}
                        isValid={isPendingItemValid}
                        onValidChanged={setIsPendingItemValid}
                        onSave={saveItem}
                        translate={translate}
                        items={items}
                    />
                </ActionItemDialog>
            ) : null}

            <Divider />

            <Stack direction="row" align-items="center" justifyContent="space-between" p={2}>
                <Stack direction="row" spacing={1}>
                    {canCreate ? (
                        <Button variant="contained" onClick={() => openCreateEditDialog()}>
                            {translate(`create_${itemName}`)}
                        </Button>
                    ) : null}
                </Stack>
                <Stack>
                    <SearchInput translate={translate} onChange={setFilter} searchKey={filter} fullWidth={false} />
                </Stack>
            </Stack>

            <Divider />

            <CRUDTable
                data={items}
                itemName={itemName}
                columns={columns}
                globalFilter={debouncedFilter}
                translate={translate}
                onUpdate={canUpdate ? openCreateEditDialog : undefined}
                onDelete={canDelete ? openDeleteDialog : undefined}
                {...tableProps}
            />
        </Stack>
    );
}
export default CRUDList;
