import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BasicMessageDto, SuperAdminCostLeverDto, SuperAdminCostLeverListDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const SUPERADMIN_COSTLEVERS_PATH = "superadmin/cost-levers";

export const useSuperadminCostLevers = () => {
    return useQuery({
        queryKey: [SUPERADMIN_COSTLEVERS_PATH],
        queryFn: ({ signal }) => apiGet<SuperAdminCostLeverListDto>(SUPERADMIN_COSTLEVERS_PATH, { signal }),
    });
};

export const useSuperadminCreateCostLever = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: Omit<SuperAdminCostLeverDto, "id">) => apiPost<SuperAdminCostLeverDto>(SUPERADMIN_COSTLEVERS_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_COSTLEVERS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_COSTLEVER_CREATED));
        },
    });
};

export const useSuperadminUpdateCostLever = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ id, ...restProps }: { id: number } & Partial<SuperAdminCostLeverDto>) =>
            apiPatch<SuperAdminCostLeverDto>(`${SUPERADMIN_COSTLEVERS_PATH}/${id}`, restProps),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_COSTLEVERS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_COSTLEVER_UPDATED));
        },
    });
};

export const useSuperadminDeleteCostLever = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (costLeverId: number) => apiDelete<BasicMessageDto>(`${SUPERADMIN_COSTLEVERS_PATH}/${costLeverId}`),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_COSTLEVERS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_COSTLEVER_DELETED));
        },
    });
};
