import { FormGroup, TextField } from "@mui/material";
import { SuperAdminDomainDto, sanitizeDomain } from "api-shared";
import React, { useState } from "react";
import isFQDN from "validator/lib/isFQDN";
import Form from "../../../components/Form";
import Select from "../../../components/input/select/Select";
import { useSuperadminClients } from "../../../domain/superadmin/clients";
import { CRUDFormProps } from "../CRUDList";

const validateDomain = (text: unknown) => typeof text === "string" && isFQDN(sanitizeDomain(text));

const validateItem = ({ clientId, name }: SuperAdminDomainDto) => clientId != null && clientId > 0 && validateDomain(name);

const isUniqueName = (name: string, items: SuperAdminDomainDto[]) => !items.some((item) => item.name === name);

const DomainCRUDForm = ({ onSave, item, updateItem, isValid, onValidChanged, items }: CRUDFormProps<SuperAdminDomainDto>) => {
    const clientsQuery = useSuperadminClients();

    const [isNameInputTouched, setIsNameInputTouched] = useState(false);

    const isInvalidDomainName = !validateDomain(item.name);

    const update = (changes: Partial<SuperAdminDomainDto>) => {
        const newItem = { ...item, ...changes };
        const isUnique = isUniqueName(newItem.name.trim(), items ?? []);
        const newValid = (validateItem(newItem) && isUnique) || !isNameInputTouched;
        updateItem(newItem);
        if (newValid !== isValid) {
            onValidChanged(newValid);
        }
    };

    const updateText = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setIsNameInputTouched(true);
        update({ [name]: value });
    };

    const clientOptions = (clientsQuery.data ?? []).map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    return (
        <Form onSubmit={onSave}>
            <Select
                label="Client"
                value={clientOptions.find((t) => t.value === item.clientId)}
                options={clientOptions}
                onChange={(option) => option != null && update({ clientId: option.value })}
                menuPortalTarget={document.body}
                isSearchable
                required
            />
            <Select
                label="Originator"
                value={clientOptions.find((t) => t.value === item.originatorId)}
                options={clientOptions}
                onChange={(option) => update({ originatorId: option?.value ?? null })}
                menuPortalTarget={document.body}
                isSearchable
                required
                isClearable
            />
            <FormGroup>
                <TextField
                    value={item.name ?? ""}
                    onChange={updateText}
                    label="name"
                    name="name"
                    required
                    error={(isInvalidDomainName || !isUniqueName(item.name.trim(), items ?? [])) && isNameInputTouched}
                    margin="normal"
                />
            </FormGroup>
        </Form>
    );
};

export default DomainCRUDForm;
