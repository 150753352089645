import { SuperAdminAttributeDto } from "api-shared";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import IdeaFieldRelationForm from "./IdeaFieldRelationForm";

interface IdeaFieldRelationDialogProps {
    onSave: (data: FieldRelationIdeasFormData) => void;
    open: boolean;
    onClose: () => void;
    attributes: SuperAdminAttributeDto[];
    attributeIdsInUse: number[];
    clientId: number;
}

export interface FieldRelationIdeasFormData {
    attributeId: number | null;
}

const IdeaFieldRelationDialog = ({ onSave, open, onClose, attributes, attributeIdsInUse, clientId }: IdeaFieldRelationDialogProps) => {
    const { t: translate } = useTranslation();
    const { reset, handleSubmit, control, formState } = useForm<FieldRelationIdeasFormData>({
        mode: "onChange",
    });

    const submit = handleSubmit(onSave);

    return (
        <ActionItemDialog
            action="create"
            item="relation"
            primary="create_relation"
            onPrimary={submit}
            primaryDisabled={!formState.isValid || !formState.isDirty}
            translate={translate}
            open={open}
            onClose={() => {
                onClose();
                reset();
            }}
        >
            <IdeaFieldRelationForm
                onSubmit={submit}
                control={control}
                attributes={attributes}
                attributeIdsInUse={attributeIdsInUse}
                clientId={clientId}
            />
        </ActionItemDialog>
    );
};

export default IdeaFieldRelationDialog;
