import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { translationKeys } from "../../translations/main-translations";

const SUPERADMIN_SUPPLIERS_PATH = "superadmin/upload-suppliers";

interface UploadSuppliersInputDto {
    clientId: number;
    file: File;
}

export const useSuperadminUploadSuppliers = () => {
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: UploadSuppliersInputDto) => {
            const formData = new FormData();
            formData.append("file", data.file);
            formData.append("clientId", `${data.clientId}`);
            return apiPost<void>(`${SUPERADMIN_SUPPLIERS_PATH}`, formData);
        },
        onSuccess: () => {
            dispatch(showNotificationEvent(NotificationType.SUCCESS, translationKeys.VDLANG_SUPERADMIN_SUPPLIERS_SUCCESS));
        },
    });
};
