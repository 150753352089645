import { ClientDto, SuperAdminTranslationDto } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import { UpdateTranslationInputDto } from "../../../domain/superadmin/translations";
import { translationKeys } from "../../../translations/main-translations";
import TranslationDialog, { TranslationFormData } from "./TranslationDialog";

interface EditTranslationDialogProps {
    open: boolean;
    onClose: () => void;
    translation: SuperAdminTranslationDto;
    updateTranslation: (data: UpdateTranslationInputDto) => void;
    translate: TFunction;
    clients: ClientDto[];
}
const EditTranslationDialog = ({
    open,
    onClose,
    translation: translationProps,
    updateTranslation,
    translate,
    clients,
}: EditTranslationDialogProps) => {
    const [isValid, setIsValid] = useState(false);
    const [translation, setTranslation] = useState<TranslationFormData>(translationProps);

    const handleTranslationChange = (changes: Partial<TranslationFormData>, isValid: boolean) => {
        setTranslation((old) => ({ ...old, ...changes }));
        setIsValid(isValid);
    };

    const close = () => {
        setIsValid(false);
        onClose();
    };

    const submitAndClose = () => {
        if (!isValid) {
            return;
        }
        updateTranslation({ translationId: translationProps.id, translation });
        close();
    };

    return (
        <ActionItemDialog
            open={open}
            onClose={close}
            action="edit"
            item="translation"
            primary={translationKeys.VDLANG_SAVE}
            onPrimary={submitAndClose}
            primaryDisabled={!isValid}
            translate={translate}
        >
            <TranslationDialog
                // `translation` state not being used for the key here since it needs to be stable for comparisons when updating
                currentTranslationKey={translationProps.key}
                translation={translation}
                updateTranslation={handleTranslationChange}
                onSave={submitAndClose}
                clients={clients}
            />
        </ActionItemDialog>
    );
};

export default EditTranslationDialog;
