import { Stack, Typography } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useSuperAdminTreeNodes } from "../../../domain/superadmin/tree-nodes";
import { useLanguage } from "../../../hooks/useLanguage";
import TreeNodes from "../../../components/TreeNodes";

type ManageTreeNodesProps = {
    clientId: number;
    attributeId: number;
};

const ManageTreeNodes = ({ clientId, attributeId }: ManageTreeNodesProps) => {
    const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null);
    const language = useLanguage();

    const handleSelect = (event: SyntheticEvent, nodeId: number | null) => {
        setSelectedNodeId(nodeId);
    };

    const treeNodes = useSuperAdminTreeNodes(clientId, attributeId);

    return (
        <Stack spacing={1} p={2}>
            {!treeNodes.isSuccess ? <LoadingAnimation /> : null}
            {treeNodes.isSuccess && treeNodes.data.length > 0 ? (
                <TreeNodes selected={selectedNodeId} handleSelect={handleSelect} treeNodes={treeNodes.data} language={language} />
            ) : null}
            {treeNodes.isSuccess && treeNodes.data.length === 0 ? (
                <Typography variant="subtitle2" color="primary">
                    No tree nodes available for this client
                </Typography>
            ) : null}
        </Stack>
    );
};

export default ManageTreeNodes;
