import { Card, CardActions, CardContent, CardHeader, List, ListItem, ListItemText, Skeleton } from "@mui/material";
import { times } from "lodash";

type FieldRelationCardSkeletonProps = {
    title: string;
};

function FieldRelationCardSkeleton({ title }: Readonly<FieldRelationCardSkeletonProps>) {
    return (
        <Card>
            <CardHeader title={title} />
            <CardContent>
                <List>
                    {times(3).map((i) => (
                        <ListItem key={i}>
                            <ListItemText>
                                <Skeleton />
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </CardContent>
            <CardActions />
        </Card>
    );
}

export default FieldRelationCardSkeleton;
