import { Button, Divider, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import UploadInput from "../../../components/UploadInput.tsx";
import ConfirmDialog from "../../../components/dialogues/ConfirmDialog.tsx";
import Select from "../../../components/input/select/Select";
import LoadingAnimation from "../../../components/loading/LoadingAnimation.tsx";
import { useSuperadminCustomers } from "../../../domain/superadmin/clients.ts";
import { useSuperadminUploadSuppliers } from "../../../domain/superadmin/suppliers.ts";
import useDialog from "../../../hooks/useDialog.ts";
import { translationKeys } from "../../../translations/main-translations.ts";
import SuperAdminContainer from "../SuperAdminContainer.tsx";

interface IFileDetails {
    selectedFile: File | null;
    hasValidFile: boolean;
}

const SuperadminSuppliers = () => {
    const [selectedClientId, setSelectedClientId] = useState<number>();

    const [fileDetails, setFileDetails] = useState<IFileDetails>({
        selectedFile: null,
        hasValidFile: false,
    });
    const { t: translate } = useTranslation();
    const { isOpen, closeDialog, openDialog } = useDialog();
    const clientsQuery = useSuperadminCustomers();
    const uploadSuppliers = useSuperadminUploadSuppliers().mutate;
    const clientOptions =
        clientsQuery.data?.map((client) => {
            return { value: client.id, label: client.name };
        }) ?? [];
    const selectedClientOption = clientOptions.find(({ value }) => value === selectedClientId);

    function selectedFileChanged(selectedFile: File, hasValidFile: boolean) {
        return setFileDetails({ selectedFile, hasValidFile });
    }

    function uploadJson() {
        const file = fileDetails.selectedFile;
        if (file === null || selectedClientId === undefined) {
            return;
        }
        uploadSuppliers(
            { clientId: selectedClientId, file },
            {
                onSuccess: () =>
                    setFileDetails({
                        selectedFile: null,
                        hasValidFile: false,
                    }),
            },
        );
    }

    if (!clientsQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <SuperAdminContainer title="Partner Upload">
            <Divider />
            <Grid container spacing={1} p={2} alignItems="flex-end">
                <Grid item xs={4} md={5}>
                    <Select
                        margin="none"
                        label="Client"
                        value={selectedClientOption}
                        options={clientOptions}
                        onChange={(newValue) => {
                            setSelectedClientId(newValue?.value);
                        }}
                        menuPortalTarget={document.body}
                        isSearchable
                        required
                        isMulti={false}
                    />
                </Grid>
                <Grid item xs={4} md={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        disabled={!fileDetails.hasValidFile || selectedClientId === undefined}
                        onClick={openDialog}
                    >
                        {translate(translationKeys.VDLANG_UPLOAD)}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {selectedClientId !== null && (
                        <UploadInput
                            translate={translate}
                            updateFile={selectedFileChanged}
                            file={fileDetails.selectedFile}
                            mimeTypes="application/json"
                        />
                    )}
                    <ConfirmDialog
                        open={isOpen}
                        onClose={closeDialog}
                        item={translationKeys.VDLANG_IMPORT}
                        translate={translate}
                        title={translate(translationKeys.VDLANG_TREE_NODE_IMPORT)}
                        onConfirm={uploadJson}
                    >
                        {translate(translationKeys.VDLANG_SUPPLIERS_IMPORT_TEXT, {
                            clientName: selectedClientOption?.label ?? "",
                        })}
                    </ConfirmDialog>
                </Grid>
            </Grid>
        </SuperAdminContainer>
    );
};

export default SuperadminSuppliers;
