import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { NumericIdParam, SuperAdminMeasureConfigDto, SuperAdminMeasureConfigListDto, SuperAdminMeasureConfigUpdateDto } from "api-shared";
import { apiGet, apiPatch } from "../../lib/api";

const SUPERADMIN_MEASURE_CONFIG_PATH = "superadmin/measure-configs";
export const SuperAdminMeasureConfigKeys = {
    all: [SUPERADMIN_MEASURE_CONFIG_PATH] as const,
    forClient: (clientId?: number) => [...SuperAdminMeasureConfigKeys.all, clientId] as const,
};

export const useSuperAdminMeasureConfigs = (clientId?: number, enabled?: boolean) => {
    return useQuery({
        queryKey: SuperAdminMeasureConfigKeys.forClient(clientId),
        queryFn: ({ queryKey, signal }) => {
            const id = clientId === undefined ? "" : `?clientId=${queryKey[1]}`;
            return apiGet<SuperAdminMeasureConfigListDto>(`${queryKey[0]}${id}`, { signal });
        },
        enabled,
    });
};

export const useSuperAdminMeasureConfigMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id, prefillGateAssignee, clientId }: SuperAdminMeasureConfigUpdateDto & NumericIdParam) =>
            apiPatch<SuperAdminMeasureConfigDto>(`${SUPERADMIN_MEASURE_CONFIG_PATH}/${id}`, { prefillGateAssignee, clientId }),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_MEASURE_CONFIG_PATH]);
        },
    });
};
